
import Vue from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import { DeleteSubscribe, PostSubscribe } from '@/api/displayAPI/UserAPI'
import { GetStoreTotalDisplay } from '@/api/displayAPI/DisplayAPI'
import MultiImage from '@/components/common/cards/MultiImage.vue'
import StoreCard from '@/components/common/widgets/StoreCardX.vue'
import Alert from '@/components/common/dialogs/Alert.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

const LIMIT = 20

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { MultiImage, StoreCard, Swiper, SwiperSlide, Alert },
  data() {
    return {
      touch: false,
      isFetching: false,
      alert: {
        show: false,
        message: '',
      },
      newStoreTopSwiperOption: {
        spaceBetween: 8,
        slidesPerView: 1.2,
        slidesOffsetAfter: 40,
      },
      newStoreBottomSwiperOption: {
        slidesPerView: 1.68,
        spaceBetween: 8,
        freeMode: true,
        slidesOffsetAfter: this.$getAppHtml.offsetWidth / 2 + 20,
      },
      bestStoreSwiperOption: {
        spaceBetween: 8,
        slidesPerView: 1.265,
        slidesOffsetAfter: 40,
      },
    }
  },
  beforeDestroy() {
    this.setCarouselActive()
  },
  computed: {
    ...mapGetters('HomeStore', ['getStoreHistory']),
    ...mapState('HomeStore', ['redirect']),
    // New Store 상단 carousel
    newStoreTopSwiper() {
      let swiper = this.$refs.newStoreTopSwiper as any
      return swiper.$swiper
    },
    // New Store 하단 carousel
    newStoreBottomSwiper() {
      let swiper = this.$refs.newStoreBottomSwiper as any
      return swiper.$swiper
    },
    // Best Store Carousel
    bsetStoreSwiper() {
      let swiper = this.$refs.bsetStoreSwiper as any
      return swiper.$swiper
    },
  },
  watch: {
    isLogined() {
      this.fetchStore()
    },
    'redirect.activeTab': {
      immediate: true,
      handler(state, preState) {
        if (preState === 1) {
          return this.setCarouselActive()
        }
        const { init } = this.getStoreHistory
        if (state === 1 && init) {
          this.fetch()
        }
        if (state === 1 && !init) {
          this.carouselSetting()
        }
      },
    },
  },
  methods: {
    ...mapActions('HomeStore', [
      'fetchSwipe',
      'fetchStore',
      'fetchStoreHistory',
    ]),
    ...mapActions('LoadingStore', ['fetchLoading']),
    ...mapActions('DialogStore', ['fetchLoginDialog']),

    async fetch() {
      this.initCaruoselActive()
      await this.fetchStore()
      this.carouselSetting()
    },
    async fetchTotalList(page) {
      if (this.isFetching) return
      this.isFetching = true
      try {
        this.fetchLoading({ yn: true })

        const { data } = await GetStoreTotalDisplay({ page, limit: LIMIT })
        const { shopList } = this.getStoreHistory

        await this.fetchStoreHistory({
          ...this.getStoreHistory,
          paging: data.paging,
          shopList: shopList.concat(data.shopList),
        })
      } catch (e) {
        console.log('error check')
      } finally {
        this.isFetching = false
        this.fetchLoading({ yn: false })
      }
    },
    handleTouch(yn) {
      if (this.touch === yn) return
      this.touch = yn
      setTimeout(() => {
        this.fetchSwipe({ yn })
      })
    },
    async subscribe({ shopId, shopName, isSubscribed }: ShopInfo) {
      try {
        await PostSubscribe(shopId, shopName)
        this.handleChangeSubscribe(!isSubscribed, shopId)
      } catch (e: any) {
        if (e && e.error_code === 400) {
          this.alert = { show: true, message: e.message }
        }
      }
    },
    async unSubscribe({ shopId, shopName, isSubscribed }: ShopInfo) {
      try {
        await DeleteSubscribe(shopId, shopName)
        this.handleChangeSubscribe(!isSubscribed, shopId)
      } catch (e) {
        console.log(e)
      }
    },
    async handleSubscribed(item: ShopInfo) {
      if (this.isLogined) {
        item.isSubscribed
          ? await this.unSubscribe(item)
          : await this.subscribe(item)
      } else {
        this.fetchLoginDialog({ yn: true })
      }
    },
    handleChangeSubscribe(yn: boolean, shopId: string) {
      const { newShopGroup, bestShopGroup, shopList } = this.getStoreHistory
      // NEW
      const replaceNewList = newShopGroup.newShopList
      const newIdx = replaceNewList.findIndex((item) => item.shopId === shopId)
      if (newIdx >= 0) {
        replaceNewList[newIdx].isSubscribed = yn
      }
      //BEST
      const replaceBestList = bestShopGroup.bestShopList
      const bestIdx = replaceBestList.findIndex(
        (item) => item.shopId === shopId
      )
      if (bestIdx >= 0) {
        replaceBestList[bestIdx].isSubscribed = yn
      }
      //TOTAL
      const replaceTotalList = shopList
      const totalIdx = replaceTotalList.findIndex(
        (item) => item.shopId === shopId
      )
      if (totalIdx >= 0) {
        replaceTotalList[totalIdx].isSubscribed = yn
      }

      // SET
      this.fetchStoreHistory({
        ...this.getStoreHistory,
        shopList: replaceTotalList,
        newShopGroup: { ...newShopGroup, newShopList: replaceNewList },
        bestShopGroup: { ...bestShopGroup, bestShopList: replaceBestList },
      })
    },
    carouselSetting() {
      this.$nextTick(() => {
        const { topCarousel, bottomCarousel, bestCarousel } =
          this.getStoreHistory.carouselActive

        this.newStoreTopSwiper.translateTo(topCarousel)
        this.newStoreBottomSwiper.translateTo(bottomCarousel)
        this.bsetStoreSwiper.translateTo(bestCarousel)
      })
    },
    onIntersect(entries: IntersectionObserverEntry[]) {
      if (!entries[0].isIntersecting) return

      const { paging } = this.getStoreHistory
      if (!paging.hasNext) return
      this.fetchTotalList(paging.page + 1)
    },
    goPage(shopId: string, gaTagName: string) {
      this.$tracking(gaTagName)
      // project 초기에 셋팅되어 있는건데, 왜 필요한건지 아래 함수 확인
      this.$router.push({
        name: 'StoreHome',
        query: { shopId },
      })
    },
    initCaruoselActive() {
      this.fetchStoreHistory({
        ...this.getStoreHistory,
        carouselActive: {
          topCarousel: 0,
          bestCarousel: 0,
          bottomCarousel: 0,
        },
      })
    },
    setCarouselActive() {
      this.fetchStoreHistory({
        ...this.getStoreHistory,
        carouselActive: {
          topCarousel: this.newStoreTopSwiper.translate,
          bottomCarousel: this.newStoreBottomSwiper.translate,
          bestCarousel: this.bsetStoreSwiper.translate,
        },
      })
    },
  },
})

interface Data {
  touch: boolean
  isFetching: boolean
  newStoreTopSwiperOption: SwiperOption
  newStoreBottomSwiperOption: SwiperOption
  bestStoreSwiperOption: SwiperOption
  alert: {
    show: boolean
    message: string
  }
}
interface Methods {
  // Store
  fetchLoading(payload: { yn: boolean }): Promise<void>
  fetchSwipe(payload: { yn: boolean }): Promise<void>
  fetchStore(): Promise<void>
  fetchStoreHistory(payload: StoreHistory): Promise<void>
  fetchLoginDialog(payload: { yn: boolean }): Promise<void>
  // Component
  fetch(): void
  fetchTotalList(page: number): void
  handleChangeSubscribe(yn: boolean, shopId: string): void
  handleTouch(yn: boolean): void
  carouselSetting(): void
  subscribe(item: ShopInfo): void
  unSubscribe(item: ShopInfo): void
  handleSubscribed(item: ShopInfo): void
  goPage(shopId: string, gaTagName: string): void
  setCarouselActive(): void
  initCaruoselActive(): void
  onIntersect(entries: IntersectionObserverEntry[]): void
}
interface Computed {
  // Store
  getStoreHistory: StoreHistory
  redirect: { activeTab: number; scroll: number[] }
  // Component
  newStoreTopSwiper: any
  newStoreBottomSwiper: any
  bsetStoreSwiper: any
}
interface Props {}
