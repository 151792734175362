var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex relative border-radius-container-8px fill-height"},[_c('div',{staticStyle:{"width":"65.77%"}},[_c('v-img',{attrs:{"src":_vm.item.bestImageItem.mainImage,"aspect-ratio":193 / 274},on:{"click":function($event){return _vm.goPage(_vm.item.bestImageItem.mainImageSearchProduct, '01')}}})],1),_c('div',{staticClass:"d-flex flex-column fill-height",staticStyle:{"padding-left":"1px","width":"34.23%"}},_vm._l((3),function(_item){return _c('div',{key:_item,staticClass:"left-images"},[(_item % 2 == 1)?_c('v-img',{staticClass:"fill-height",attrs:{"src":_vm.item.bestImageItem[_item === 1 ? 'subImage1' : 'subImage2'],"aspect-ratio":100 / 136},on:{"click":function($event){return _vm.goPage(
            _vm.item.bestImageItem[
              _item === 1
                ? 'subImage1SearchProduct'
                : 'subImage2SearchProduct'
            ],
            _item === 1 ? '02' : '03'
          )}}}):_vm._e(),(_item % 2 == 0)?_c('div',{staticClass:"white",staticStyle:{"height":"1px"}}):_vm._e()],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }