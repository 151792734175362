
import Vue from 'vue'
import ProductCardY from '@/components/common/widgets/ProductCardY.vue'
import { mapActions, mapGetters } from 'vuex'
import ButtonMore from '@/components/common/buttons/ButtonMore.vue'

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { ButtonMore, ProductCardY },
  computed: {
    ...mapGetters('HomeStore', ['getMainAllItemHistory']),
  },
  methods: {
    ...mapActions('HomeStore', ['fetchMainHistory']),
    goPage(topMore?: boolean) {
      this.$tracking(`home_Items_more_${topMore ? '01' : '02'}`)
      this.$router.push({
        name: 'CategoryProductList',
        query: { categoryCode: '7', categoryName: '전체' },
      })
    },
    isLikeChange(isLike: boolean, productNo: string, index: number) {
      const replaceList = this.getMainAllItemHistory.searchProductList
      const idx = replaceList.findIndex((item) => item.productNo === productNo)
      replaceList[idx].isLike = isLike

      const trackingName = `home_Items_${this.$numbering(index + 1)}`
      if (isLike) this.$tracking(`${trackingName}_like`)
      else this.$tracking(`${trackingName}_unlike`)

      this.fetchMainHistory({
        id: 'allItem',
        allItem: {
          ...this.getMainAllItemHistory,
          searchProductList: replaceList,
        },
      })
    },
  },
})

interface Data {}
interface Methods {
  goPage(topMore?: boolean): void
  isLikeChange(isLike: boolean, productNo: string, index: number): void
  fetchMainHistory(payload: MainHistoryPayload): void
}
interface Computed {
  // api v2 후 수정 예정
  getMainAllItemHistory: AllItemsGroup
}
interface Props {
  lazyMount: boolean
}
