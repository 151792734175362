var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PullToRefresh',{staticClass:"pb-64",on:{"pullToState":_vm.pullToState,"handleRefresh":_vm.handleRefresh}},[_c('v-skeleton-loader',{directives:[{name:"show",rawName:"v-show",value:(_vm.bannderLoading),expression:"bannderLoading"}],staticClass:"relative fill-width",attrs:{"id":"main_bigBanner","height":_vm.$getAppHtml.offsetWidth * 1.12,"type":"image"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.bannderLoading),expression:"!bannderLoading"},{name:"touch",rawName:"v-touch",value:({
      start: (e) => _vm.preventDifferentSwipe(true),
      end: () => _vm.preventDifferentSwipe(false),
    }),expression:"{\n      start: (e) => preventDifferentSwipe(true),\n      end: () => preventDifferentSwipe(false),\n    }"}],staticClass:"relative"},[(_vm.getBigBanner.bannerList.length > 0)?_c('div',{style:(_vm.bigBannerHeight)},[_c('div',{staticClass:"relative",staticStyle:{"z-index":"5"}},[_c('div',{staticClass:"absolute z-index-1 pr-5",style:(`height : ${_vm.$getAppHtml.offsetWidth * 1.12}px`),on:{"click":function($event){return _vm.$swiper.slidePrev()}}},[_c('v-img',{staticClass:"arrow ml-10",attrs:{"src":require("../../../assets/images/arrowPrevWhiteSquare.svg"),"transition":"cl-img"}})],1),_c('div',{staticClass:"absolute right-0 ml-auto z-index-1 pl-5",style:(`height : ${_vm.$getAppHtml.offsetWidth * 1.12}px`),on:{"click":function($event){return _vm.$swiper.slideNext()}}},[_c('v-img',{staticClass:"arrow mr-10",attrs:{"src":require("../../../assets/images/arrowNextWhiteSquare.svg"),"transition":"cl-img"}})],1)]),_c('swiper',{directives:[{name:"intersect",rawName:"v-intersect",value:({
          handler: _vm.handleBigBannerCycle,
          options: {
            threshold: [0, 0.2, 0.5, 1.0],
          },
        }),expression:"{\n          handler: handleBigBannerCycle,\n          options: {\n            threshold: [0, 0.2, 0.5, 1.0],\n          },\n        }"}],ref:"awesomeSwiper",staticClass:"swiper",staticStyle:{"height":"100%"},attrs:{"options":_vm.swiperOption},on:{"ready":_vm.swiperReady,"slide-change":_vm.slideChangeFc}},_vm._l((_vm.getBigBanner.bannerList),function(item,index){return _c('swiper-slide',{key:index},[_c('div',{staticClass:"fill-width fill-height"},[_c('img',{staticClass:"fill-width fill-height",attrs:{"src":item.icon,"transition":"cl-img"},on:{"~load":function($event){return _vm.loadChecked(index)}}}),_c('div',{staticClass:"absolute d-flex flex-column white--text fill-width bottom-0 height-81 gradtionBlack"},[_c('div',{staticClass:"absolute fill-width bottom-0"},[_c('div',{staticClass:"font-g28-34 rixgo-ultralight mx-15 mb-8",domProps:{"innerHTML":_vm._s(_vm.$replaceHtml(item.title))}}),_c('div',{staticClass:"font-g12-18 rixgo-regular mx-15 mb-15",domProps:{"innerHTML":_vm._s(_vm.$replaceHtml(item.description))}}),_c('div',{staticClass:"fill-width d-flex justify-space-between mb-15",staticStyle:{"z-index":"5"}},[_c('div',{staticClass:"d-inline-block font-g12-13 campton-bold white--text ml-15"},[_c('span',[_vm._v(_vm._s(_vm._f("transformatNumber")(_vm.bigBannerActive)))])]),_c('v-progress-linear',{staticClass:"width-70 mt-3",attrs:{"height":"2","background-color":"rgba(255, 255, 255, 0.5)","color":"white","value":(_vm.bigBannerActive / _vm.getBigBanner.bannerList.length) * 100}}),_c('div',{staticClass:"d-inline-block font-g12-13 campton-bold white--text opacity-6 pr-15"},[_c('span',[_vm._v(_vm._s(_vm._f("transformatNumber")(_vm.getBigBanner.bannerList.length)))])])],1)])])])])}),1)],1):_vm._e()]),_c('div',{style:(_vm.stripBannerHeight)},[(_vm.getStripBanner.bannerList.length > 0)?_c('v-carousel',{directives:[{name:"intersect",rawName:"v-intersect",value:({
        handler: _vm.handleTripBannerCycle,
        options: {
          threshold: [0, 0.2, 0.5, 1.0],
        },
      }),expression:"{\n        handler: handleTripBannerCycle,\n        options: {\n          threshold: [0, 0.2, 0.5, 1.0],\n        },\n      }"}],attrs:{"hide-delimiters":"","cycle":_vm.tripBannerCycle,"show-arrows":false,"interval":_vm.getStripBanner.rollingSecTime * 1000,"reverse":_vm.$vueCarouselCycle(_vm.getStripBanner.bannerList, _vm.subBannerActive),"height":"100%"},model:{value:(_vm.subBannerActive),callback:function ($$v) {_vm.subBannerActive=$$v},expression:"subBannerActive"}},_vm._l((_vm.getStripBanner.bannerList),function(item,index){return _c('v-carousel-item',{key:index,staticClass:"fill-height"},[_c('v-img',{attrs:{"aspect-ratio":375 / 110,"src":item.icon},on:{"~load":function($event){return _vm.checkLoadStripBanner(index)}},nativeOn:{"click":function($event){return _vm.goStripBannerPage(item.link, index)}}})],1)}),1):_vm._e()],1),_vm._l((_vm.arrangeList),function(item,index){return _c(_vm._f("displayComponent")(item),{key:index,tag:"component"})}),_c('TopShop'),_c('Trend'),_c('AllItem'),_c('Footer')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }