
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import MainView from '@/components/views/main/index.vue'

export default Vue.extend<Data, Methods, Computed>({
  name: 'Main',
  components: { MainView },
  data() {
    return {}
  },

  async created() {
    const shareItem = this.$route.query.shareItem
    if (shareItem && typeof shareItem === 'string') {
      history.replaceState(null, 'null', '/')
      this.$rendingProduct(shareItem)
      return
    }
    // 기획전 공유라면
    const exhibitionNo = this.$route.query.exhibitionNo
    if (exhibitionNo && typeof exhibitionNo === 'string') {
      history.replaceState(null, 'null', '/')
      this.$rendExhibition(exhibitionNo)
      return
    }
    // storeHome 공유라면
    const shopId = this.$route.query.shopId
    if (shopId && typeof shopId === 'string') {
      history.replaceState(null, 'null', '/')
      this.$router.push(`/storeHome?shopId=${shopId}`)
      return
    }
    // 이벤트 공유라면
    const eventShopId = this.$route.query.eventShopId
    if (eventShopId && typeof eventShopId === 'string') {
      history.replaceState(null, 'null', '/')
      this.$router.push(`/exhibitionEvent?eventShopId=${eventShopId}`)
      return
    }
    // 어드민 단일 기획전일때
    const singlePromotionNo = this.$route.query.singlemallNo
    if (singlePromotionNo && typeof singlePromotionNo === 'string') {
      history.replaceState(null, 'null', '/')
      this.$router.push(`/singleMallPromotion?no=${singlePromotionNo}`)
      return
    }

    const multiPromotionNo = this.$route.query.multiPromotionNo
    if (multiPromotionNo && typeof multiPromotionNo === 'string') {
      history.replaceState(null, 'null', '/')
      this.$router.push(
        `/promotionMultiMall?multiPromotionNo=${multiPromotionNo}`
      )
      return
    }

    //멀티몰 리뉴얼
    const multiPromotionNoNew = this.$route.query.multimallNo
    if (multiPromotionNoNew && typeof multiPromotionNoNew === 'string') {
      history.replaceState(null, 'null', '/')
      await this.fetchMultiMallExhibitionData({})
      this.$router.push(`/multiMallPromotion?no=${multiPromotionNoNew}`)
      return
    }
    //회원가입 이벤트
    const promotionSignUp = this.$route.query.promotionSignUp
    if (promotionSignUp && typeof promotionSignUp === 'string') {
      history.replaceState(null, 'null', '/')
      this.$router.push(`/promotionSignUp`)
      return
    }

    //이벤트
    const eventNo = this.$route.query.eventNo
    if (eventNo && typeof eventNo === 'string') {
      history.replaceState(null, 'null', '/')
      this.$router.push(`/event?eventNo=${eventNo}`)
      return
    }
  },
  watch: {
    'pageHistory.main.init': {
      immediate: true,
      handler(val) {
        if (val) {
          this.asyncGetDisplay()
        }
      },
    },
  },
  mounted() {
    this.$trackingConversion()
  },
  computed: {
    ...mapState('HomeStore', ['pageHistory']),
  },
  methods: {
    ...mapActions('HomeStore', ['fetchHomeHistory']),
    ...mapActions('DisplayStore', ['asyncGetDisplay']),
    ...mapActions('ExhibitionStore', ['fetchMultiMallExhibitionData']),
  },
})
interface Data {}
interface Methods {
  asyncGetDisplay(): void
  fetchHomeHistory(): void
  fetchMultiMallExhibitionData(payload: any): void
}
interface Computed {
  pageHistory: HomeHistory
}
