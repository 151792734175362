export const ProductItemData = {
  no: 0,
  docId: '',
  docState: '',
  goodsNo: '',
  productNo: '',
  nameKr: '',
  nameEn: '',
  shopName: '',
  categoryCode: '',
  category1: '',
  categoryCode1: '',
  category2: '',
  categoryCode2: '',
  category3: '',
  categoryCode3: '',
  category4: '',
  categoryCode4: '',
  categoryNameFromMall: '',
  sex: null,
  age: '',
  price: 0,
  discountPrice: 0,
  discountRate: 0,
  mallScore: 0,
  mallIcon: '',
  mallId: '',
  productScore: 0,
  weekScore: 0,
  monthScore: 0,
  newarrivalScore: 0,
  latestScore: 0,
  mdOrder: 0,
  viewCnt: 0,
  saleCnt: 0,
  likeCnt: 0,
  terms: '',
  tags: '',
  showaTag1: '',
  showaTag2: '',
  colors: '',
  isLike: false,
  isBest: false,
  isNew: false,
  fullTerms: '',
  urlPc: '',
  urlMobile: '',
  imageUrl1: '',
  imageUrl2: '',
  isAvailable: true,
  imageInfo: null,
  updateTime: '',
  createTime: '',
}
