
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { GetNewArrivalsProduct } from '@/api/displayAPI/DisplayAPI'
import ButtonMore from '@/components/common/buttons/ButtonMore.vue'
import ProductCardY from '@/components/common/widgets/ProductCardY.vue'
import Slider from '@/components/common/slider/Slider.vue'
import _ from 'lodash'

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    ButtonMore,
    ProductCardY,
    Slider,
  },
  data() {
    return {
      list: [],
      count: 0,
    }
  },
  updated() {
    console.log('##', this.getNewArrivalsHistory.active)
  },
  computed: {
    ...mapState('HomeStore', ['pageHistory', 'redirect']),
    ...mapGetters('HomeStore', ['getNewArrivalsHistory']),
  },
  methods: {
    ...mapActions('DialogStore', ['fetchLoginDialog']),
    ...mapActions('LoadingStore', ['fetchLoading']),
    ...mapActions('HomeStore', ['fetchMainHistory', 'fetchSwipe']),
    more() {
      const { active, newArrivalsItemList } = this.getNewArrivalsHistory
      const { title, shopId } = newArrivalsItemList[active.index]

      if (title === '전체') {
        this.$tracking('home_new_more01')
        this.$router.push({
          name: 'CategoryNewList',
          query: { categoryName: 'NEW ARRIVALS' },
        })
      } else {
        this.$tracking(`home_new_more${this.$numbering(active.index + 1)}`)
        this.$router.push({
          name: 'StoreHome',
          query: { shopId },
        })
      }
    },
    async handleActive(item: any, index: number, fetch?: boolean) {
      const { active } = this.getNewArrivalsHistory
      if (!fetch && item.id === active.id) return
      if (!fetch) this.fetchLoading({ yn: true })
      let list: ProductItem[] = []
      try {
        if (index === 0) {
          const { newArrivalsItemList } = this.getNewArrivalsHistory

          list = _.cloneDeep(newArrivalsItemList[index].searchProductList)
          this.$tracking('home_new_allmall')
          return
        }
        const { data } = await GetNewArrivalsProduct({
          newArrivalsShopId: item.id,
        })
        list = data
        this.$tracking(`home_mall_${this.$numbering(index + 1)}`)
      } catch (e) {
        console.log('new arrivals select list e', e)
      } finally {
        const { newArrivalsItemList, title } = this.getNewArrivalsHistory
        if (index >= 0) {
          this.count = newArrivalsItemList[index].productCount
        }
        this.fetchMainHistory({
          id: 'newArrivals',
          newArrivals: {
            ...this.getNewArrivalsHistory,
            displayCount: newArrivalsItemList[index].productCount ?? 0,
            active: {
              id: item.id,
              index,
              shopName: item.shopName ?? title,
            },
            searchProductList: list,
          },
        })
        if (!fetch) this.fetchLoading({ yn: false })
      }
    },
    goPage() {
      if (this.isLogined) {
        this.$tracking('home_new_mymall')
        localStorage.removeItem('selectTab')
        this.$router.push({ name: 'Dibs', query: { acitve: 'subs' } })
      } else {
        this.fetchLoginDialog({ yn: true })
      }
    },
    mainSwipeHandler(data: string) {
      if (data !== 'off') {
        this.fetchSwipe({ yn: true })
      } else {
        this.fetchSwipe({ yn: false })
      }
    },
    isLikeChange(isLike: boolean, productNo: string) {
      const { active } = this.getNewArrivalsHistory
      const replaceList = _.cloneDeep(this.getNewArrivalsHistory)
      const { searchProductList, newArrivalsItemList } = replaceList
      try {
        if (active.id === 0) {
          const list = newArrivalsItemList[active.id].searchProductList
          const idx = list.findIndex((item) => item.productNo === productNo)
          list[idx].isLike = isLike
        } else {
          const idx = searchProductList.findIndex(
            (item) => item.productNo === productNo
          )
          searchProductList[idx].isLike = isLike
        }
      } finally {
        this.fetchMainHistory({
          id: 'newArrivals',
          newArrivals: {
            ...replaceList,
          },
        })
      }
    },
  },
})

interface Data {
  list: ProductItem[]
  count: number
}

interface Computed {
  getNewArrivalsHistory: NewArrivalsInfo
  pageHistory: HomeHistory
  redirect: {
    activeTab: number
    scroll: number[]
  }
}

interface Methods {
  more(): void
  handleActive(item: any, index: number, init?: boolean): void
  goPage(): void
  fetchLoginDialog({ yn }: { yn: boolean }): void
  fetchLoading(yn: { yn: boolean }): void
  fetchMainHistory(payload: MainHistoryPayload): void
  mainSwipeHandler(data: string): void
  fetchSwipe(payload: { yn: boolean }): void
  isLikeChange(isLike: boolean, productNo: string): void
}

interface Props {}
