var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PullToRefresh',{on:{"handleRefresh":_vm.fetch}},[_c('div',{staticClass:"pb-64"},[_c('div',[_c('div',{staticClass:"font-g20-30 gray900--text campton-medium font-weight-500 pt-20 px-10"},[_vm._v(" "+_vm._s(_vm.getStoreHistory.newShopGroup.title)+" ")]),_c('div',{directives:[{name:"touch",rawName:"v-touch",value:({
          start: () => _vm.handleTouch(true),
          end: () => _vm.handleTouch(false),
        }),expression:"{\n          start: () => handleTouch(true),\n          end: () => handleTouch(false),\n        }"}],staticClass:"relative pt-8"},[_c('Swiper',{ref:"newStoreTopSwiper",staticClass:"swiper topCarousel",attrs:{"options":_vm.newStoreTopSwiperOption}},_vm._l((_vm.getStoreHistory.newShopGroup.newShopList),function(item,index){return _c('SwiperSlide',{key:'topCarousel' + index},[_c('div',{staticClass:"border-radius-container-16px",on:{"click":function($event){_vm.goPage(item.shopId, `store_newstore_${_vm.$numbering(index + 1)}`)}}},[_c('v-img',{staticClass:"relative",staticStyle:{"height":"auto"},attrs:{"src":item.mainImage,"aspect-ratio":295 / 425}},[(!item.isSubscribed)?_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.handleSubscribed(item)}}},[_c('v-icon',{staticClass:"absolute right-0 mr-5 mt-7"},[_vm._v("$vuetify.icons.bookmarkWhiteIcon")])],1):_vm._e(),(item.isSubscribed)?_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.handleSubscribed(item)}}},[_c('v-icon',{staticClass:"absolute right-0 mr-5 mt-7"},[_vm._v("$vuetify.icons.bookmarkWhiteActiveIcon")])],1):_vm._e()])],1)])}),1)],1)]),_c('v-img',{directives:[{name:"touch",rawName:"v-touch",value:({
        start: () => _vm.handleTouch(true),
        end: () => _vm.handleTouch(false),
      }),expression:"{\n        start: () => handleTouch(true),\n        end: () => handleTouch(false),\n      }"}],staticClass:"mt-30 d-flex align-center",staticStyle:{"height":"auto"},attrs:{"src":_vm.getStoreHistory.selectedShopGroup.backgroundImage,"aspect-ratio":375 / 422,"eager":""}},[_c('Swiper',{ref:"newStoreBottomSwiper",staticClass:"swiper bottomCarousel",attrs:{"options":_vm.newStoreBottomSwiperOption}},_vm._l((_vm.getStoreHistory.selectedShopGroup
            .selectedShopList),function(item,index){return _c('SwiperSlide',{key:'bottomCarousel' + index},[_c('div',{staticClass:"border-radius-container-8px",on:{"click":function($event){_vm.goPage(item.shopId, `store_selected_${_vm.$numbering(index + 1)}`)}}},[_c('v-img',{staticStyle:{"height":"auto"},attrs:{"src":item.mainImage,"aspect-ratio":220 / 292}})],1)])}),1)],1),_c('div',{staticClass:"mt-30"},[_c('div',{staticClass:"px-10"},[_c('div',{staticClass:"font-g20-30 gray900--text campton-medium font-weight-500"},[_vm._v(" "+_vm._s(_vm.getStoreHistory.bestShopGroup.title)+" ")]),_c('div',{staticClass:"font-g12-18 gray500--text rixgo-regular"},[_vm._v(" "+_vm._s(_vm.getStoreHistory.bestShopGroup.subTitle)+" ")])]),_c('div',{directives:[{name:"touch",rawName:"v-touch",value:({
          start: () => _vm.handleTouch(true),
          end: () => _vm.handleTouch(false),
        }),expression:"{\n          start: () => handleTouch(true),\n          end: () => handleTouch(false),\n        }"}],staticClass:"pt-8"},[_c('Swiper',{ref:"bsetStoreSwiper",staticClass:"swiper bestStoreCarousel",attrs:{"options":_vm.bestStoreSwiperOption}},_vm._l((_vm.getStoreHistory.bestShopGroup
              .bestShopList),function(item,index){return _c('SwiperSlide',{key:'bestCarousel' + index},[_c('MultiImage',{staticStyle:{"height":"auto"},attrs:{"item":item,"gtagName":`store_beststore_${_vm.$numbering(index + 1)}`},on:{"rendingProduct":_vm.setCarouselActive}}),_c('StoreCard',{staticClass:"mt-8",attrs:{"item":item,"contentSize":"50%","gtagName":`store_beststore_${_vm.$numbering(index + 1)}`,"bestStore":true},on:{"onChangeSubscribe":_vm.handleChangeSubscribe}})],1)}),1)],1)]),_c('div',{staticClass:"mt-30 px-10"},[_c('div',[_c('div',{staticClass:"font-g20-30 gray900--text campton-medium font-weight-500"},[_vm._v(" "+_vm._s(_vm.getStoreHistory.title)+" ")]),_c('div',{staticClass:"font-g12-18 gray500--text rixgo-regular"},[_vm._v(" "+_vm._s(_vm.getStoreHistory.subTitle)+" ")])]),_c('div',{staticClass:"mt-16"},[(_vm.getStoreHistory.shopList.length > 0)?_c('div',[_vm._l((_vm.getStoreHistory.shopList),function(item,index){return _c('div',{key:index,ref:"storeCard",refInFor:true},[_c('StoreCard',{attrs:{"item":item,"gtagName":`store_total_${_vm.$numbering(index + 1)}`},on:{"onChangeSubscribe":_vm.handleChangeSubscribe}}),(index + 1 !== _vm.getStoreHistory.shopList.length)?_c('v-divider',{staticClass:"pb-8 mt-8 border-gray200"}):_vm._e()],1)}),_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
              handler: (e) => _vm.onIntersect(e),
              options: {
                rootMargin: '300px 0px 0px 0px',
              },
            }),expression:"{\n              handler: (e) => onIntersect(e),\n              options: {\n                rootMargin: '300px 0px 0px 0px',\n              },\n            }"}]})],2):_vm._e()])]),_c('Alert',{attrs:{"show":_vm.alert.show,"content":_vm.alert.message},on:{"onClose":function($event){_vm.alert = { show: false, message: '' }}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }