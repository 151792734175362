
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import BestSeller from '@/components/views/main/component/BestSeller.vue'
import NewArrivals from '@/components/views/main/component/NewArrivals.vue'
import TopShop from '@/components/views/main/component/TopShop.vue'
import Trend from '@/components/views/main/component/Trend.vue'
import AllItem from '@/components/views/main/component/AllItem.vue'
import MyCollection from '@/components/views/main/component/MyCollection.vue'
import Footer from '@/components/common/layout/Footer.vue'
import { PostAdOnClick, PostAdOnView } from '@/api/displayAPI/DisplayAPI'
import { adOnTracking } from '@/plugins/util'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

import 'swiper/css/swiper.css'
import { onRequestAdonBanner } from '@/helper/banner'
import ShoppingLive from '@/components/views/main/component/ShoppingLive.vue'

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'MainView',
  components: {
    ShoppingLive,
    BestSeller,
    NewArrivals,
    TopShop,
    Trend,
    AllItem,
    Footer,
    MyCollection,
    Swiper,
    SwiperSlide,
  },
  filters: {
    displayComponent(item: any) {
      let componentName = ''
      switch (item) {
        case 'bestSeller':
          componentName = 'BestSeller'
          break
        case 'newArrivals':
          componentName = 'NewArrivals'
          break
        // TODO : 2022-04-19 myCollection 정책 정의 전까지 기능 none
        // case 'My Collection':
        //   componentName = 'MyCollection'
        //   break
      }
      return componentName
    },
    transformatNumber(data: number) {
      const index = data
      return index < 10 ? '0' + index : index
    },
  },
  data() {
    return {
      noticeDialog: false,
      bigBannerActive: 1,
      subBannerActive: 0,
      tripBannerCycle: true,
      bannderLoading: true,
      // touchless: false,
      isBigBannerCompleteShow: false,
      isStripBannerCompleteShow: false,
      arrangeList: ['bestSeller', 'newArrivals'],
      swiperOption: {
        preloadImages: true,
        lazy: false,
        slidesPerView: 1,
        loop: true,
        autoplay: false,
      },
      preventTimer: null,
    }
  },
  watch: {
    async user() {
      if (this.user.userType) {
        return (this.arrangeList = ['newArrivals', 'bestSeller'])
      } else {
        return (this.arrangeList = ['bestSeller', 'newArrivals'])
      }
    },
    bigBannerActive() {
      if (this.redirect.activeTab !== 0) return
      if (!this.isBigBannerCompleteShow || this.bannderLoading) return

      adOnTracking(
        this.getBigBanner.bannerList[this.bigBannerActive - 1]
          .bannerViewEventUrl,
        (key) => PostAdOnView({ key: key })
      )
    },
    subBannerActive() {
      if (this.redirect.activeTab !== 0) return
      if (!this.isStripBannerCompleteShow || this.bannderLoading) return

      adOnTracking(
        this.getStripBanner.bannerList[this.subBannerActive].bannerViewEventUrl,
        (key) => PostAdOnView({ key: key })
      )
    },
  },
  async created() {
    this.fetchOnSkeleton(true)
    if (this.user.userType) {
      return (this.arrangeList = ['newArrivals', 'bestSeller'])
    } else {
      return (this.arrangeList = ['bestSeller', 'newArrivals'])
    }
  },
  computed: {
    ...mapState('HomeStore', ['redirect']),
    ...mapState('UserStore', ['user']),
    ...mapGetters('BannerStore', ['getBigBanner', 'getStripBanner']),
    $swiper() {
      const swiper = this.$refs.awesomeSwiper as any
      return swiper.$swiper
    },
    bigBannerHeight() {
      return `height : ${this.$getAppHtml.offsetWidth * 1.12}px`
    },
    stripBannerHeight() {
      return `height :${this.$getAppHtml.offsetWidth * 0.294}px`
    },
  },
  methods: {
    ...mapActions('HomeStore', [
      'fetchOnSkeleton',
      'fetchMainInit',
      'fetchSwipe',
    ]),
    ...mapActions('DisplayStore', ['asyncGetDisplay']),
    ...mapActions('HomeStore', ['fetchOnSkeleton']),
    handleBigBannerCycle(entries) {
      const { intersectionRatio } = entries[0]
      if (!this.$swiper) return

      if (intersectionRatio >= 0.2) {
        this.$swiper.autoplay.start()
      } else {
        this.$swiper.autoplay.stop()
      }
      this.isBigBannerCompleteShow = intersectionRatio >= 0.99
    },
    handleTripBannerCycle(entries) {
      this.tripBannerCycle = entries[0].intersectionRatio >= 0.2
      this.isStripBannerCompleteShow = entries[0].intersectionRatio >= 0.99
    },
    loadChecked(index: number) {
      // 자연스러움을 위해 setTime을 넣었지만, 리팩 대상
      if (!this.bannderLoading) return
      setTimeout(() => {
        this.swiperOption.autoplay = {
          delay: 3000,
        }
        this.$swiper.autoplay.start()
        this.bannderLoading = false
        this.fetchOnSkeleton(false)

        if (index === 0) {
          adOnTracking(
            this.getBigBanner.bannerList[0].bannerViewEventUrl,
            (key) => PostAdOnView({ key: key })
          )
        }
      }, 100)
    },
    goBigBannerPage() {
      adOnTracking(
        this.getBigBanner.bannerList[this.bigBannerActive - 1].bannerClickUrl,
        (key) => PostAdOnClick({ key: key })
      )

      this.$tracking(`banner_main${this.$numbering(this.bigBannerActive - 1)}`)

      const payload = {
        url: this.getBigBanner.bannerList[this.bigBannerActive - 1].link,
        title: 'CELLOOK',
        isGoneBottomView: false,
      }
      this.$rendingOutsidePageOrInternal(this.$router, this.$device, payload)
    },
    goStripBannerPage(link: string, index: number) {
      adOnTracking(
        this.getStripBanner.bannerList[this.subBannerActive].bannerClickUrl,
        (key) => PostAdOnClick({ key: key })
      )

      this.$tracking(`banner_main_long_${this.$numbering(index + 1)}`)
      const payload = {
        url: link,
        title: 'CELLOOK',
        isGoneBottomView: false,
      }
      this.$rendingOutsidePageOrInternal(this.$router, this.$device, payload)
    },
    pullToState(yn) {
      this.fetchSwipe({ yn })
      // this.touchless = yn
    },
    async handleRefresh() {
      if (this.$route.name) onRequestAdonBanner(this.$route.name)
      this.bigBannerActive = 1
      this.$swiper.slideTo(1)
      this.handlePreventTimer()
      this.fetchMainInit(true)
      this.asyncGetDisplay()
    },
    checkLoadStripBanner(index: number) {
      //플립배너, 빅배너와 달리 기기에 따라 보이지 않을 수 있으므로 로직필요
      if (!this.isStripBannerCompleteShow) {
        return
      }

      if (index === 0) {
        console.log('Strip 배너 ADON View 트래킹')
        adOnTracking(
          this.getStripBanner.bannerList[0].bannerViewEventUrl,
          (key) => PostAdOnView({ key: key })
        )
      }
    },
    preventDifferentSwipe(yn) {
      setTimeout(() => {
        this.fetchSwipe({ yn })
      })
    },
    swiperReady() {
      this.$swiper.$el[0].addEventListener('click', this.goBigBannerPage)
    },
    slideChangeFc() {
      // vue-awesome-carousel loop option 적용 시, 경우 맨 앞에 / 맨 뒤에 duplicate issue 존재
      // 비슷한 이슈
      // https://hjh0827.tistory.com/60
      // 해결 참조
      // bigBannerActive에 swiper<ref>를 binding 하여 해당 마지막과 처음에 데이터 변경
      // https://velog.io/@soulee__/Vue.js-%EA%B3%B5%EC%8B%9D%EB%AC%B8%EC%84%9C-%EB%9C%AF%EC%96%B4%EB%B3%B4%EA%B8%B0-Refs

      // 위 이슈 때문에 한 바퀴 돌고 난 뒤 duplicate slide로 인해 click handler를 따로 빼둠
      // 참조 이슈  : https://github.com/surmon-china/vue-awesome-swiper/issues/226

      this.handlePreventTimer()

      const { activeIndex } = this.$swiper
      const { bannerList } = this.getBigBanner
      if (activeIndex == 0) {
        return (this.bigBannerActive = bannerList.length)
      }
      if (activeIndex > bannerList.length) {
        return (this.bigBannerActive = 1)
      }
      this.bigBannerActive = activeIndex
    },
    handlePreventTimer() {
      if (this.preventTimer) {
        clearTimeout(this.preventTimer)
        this.preventTimer = null
      }

      const { autoplay } = this.$swiper

      if (!autoplay.running) {
        this.preventTimer = setTimeout(() => {
          this.swiperOption.autoplay = {
            delay: 3000,
          }
          autoplay.start()
        }, 500)
      }
    },
  },
})

interface Data {
  noticeDialog: boolean
  bigBannerActive: number
  subBannerActive: number
  tripBannerCycle: boolean
  bannderLoading: boolean
  // touchless: boolean
  arrangeList: string[]
  isBigBannerCompleteShow: boolean
  isStripBannerCompleteShow: boolean
  swiperOption: {
    preloadImages: boolean
    lazy: boolean
    slidesPerView: number
    loop: boolean
    autoplay: { delay: number } | boolean
  }
  preventTimer: number | null
}

interface Props {}

interface Methods {
  handleBigBannerCycle(entries: any): void
  handleTripBannerCycle(entries: any): void
  loadChecked(index: number): void
  fetchOnSkeleton(payload: boolean): void
  goBigBannerPage(): void
  goStripBannerPage(link: string, index: number): void
  pullToState(e: any): void
  handleRefresh(): void
  asyncGetDisplay(): Promise<void>
  fetchMainInit(payload: boolean): Promise<void>
  fetchSwipe(payload: { yn: boolean }): Promise<void>
  asyncGetDisplay: () => Promise<void>
  checkLoadStripBanner(index: number): void
  preventDifferentSwipe(yn: boolean): void
  slideChangeFc(e?: any): void
  handlePreventTimer(): void
  swiperReady(): void
}

interface Computed {
  getBigBanner: AdonBannerList
  getStripBanner: AdonBannerList
  user: {
    birthYear: string
    email: string
    name: string
    nickName: string
    phone: string
    sex: string
    signUpDt: string
    userType: number | null // 1003 Naver
  }
  redirect: any
  $swiper: any
  bigBannerHeight: string
  stripBannerHeight: string
}
