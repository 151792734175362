
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import Product from '@/components/common/cards/Product.vue'
import {
  GetTrendHotRank,
  GetTrendTheseDayRise,
} from '@/api/displayAPI/DisplayAPI'

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { Product },
  props: {
    lazyMount: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // id 값
      active: {
        hot: 0,
        rise: 0,
      },
      hotList: [],
      riseList: [],
    }
  },
  created() {
    const { hotActive, riseActive } = this.getTrendHistory
    this.active = {
      hot: hotActive.id,
      rise: riseActive.id,
    }

    if (hotActive.list.length) {
      this.hotList = hotActive.list
    } else {
      this.handleActiveCategory('hot')
    }

    if (riseActive.list.length) {
      this.riseList = riseActive.list
    } else {
      this.handleActiveCategory('rise')
    }
  },
  computed: {
    ...mapState('HomeStore', ['pageHistory', 'redirect']),
    ...mapGetters('HomeStore', ['getTrendHistory', 'getPlanexHistory']),
  },
  watch: {
    'pageHistory.main.init'(yn) {
      // watch init (display API request) 변경을 감지 false ( response ) 를 받았을 때, fetch
      if (!yn) {
        const { hotActive, riseActive } = this.getTrendHistory
        this.active = {
          hot: hotActive.id,
          rise: riseActive.id,
        }

        this.handleActiveCategory('hot')
        this.handleActiveCategory('rise')
      }
    },
  },
  methods: {
    ...mapActions('LoadingStore', ['fetchLoading']),
    ...mapActions('HomeStore', ['fetchMainHistory']),
    ...mapActions('SearchStore', ['fetchClearResponse', 'fetchInitChip']),
    ...mapMutations('BridgeStore', [
      'setShowBridge',
      'setSrc',
      'setShopId',
      'setProductNo',
      'setMallName',
    ]),
    handleActive(data) {
      if (data === 0) {
        this.$tracking('home_trend_hot')
      } else {
        this.$tracking('home_trend_rising')
      }
      this.fetchMainHistory({
        id: 'trend',
        trend: { ...this.getTrendHistory, active: data },
      })
    },
    setActiveCate(active, index, type) {
      this.$tracking(
        `home_trend_${type === 'hot' ? 'hot' : 'rising'}_cate${this.$numbering(
          index + 1
        )}`
      )
      this.active[type === 'hot' ? 'hot' : 'rise'] = active.id
      this.fetchMainHistory({
        id: 'trend',
        trend: {
          ...this.getTrendHistory,
          [type === 'hot' ? 'hotActive' : 'riseActive']: { ...active },
        },
      })
      this.handleActiveCategory(type, true)
    },
    async handleActiveCategory(type, clickYn) {
      if (clickYn) this.fetchLoading({ yn: true })
      try {
        // '지금핫한' 카테고리 검색
        const { hotActive, riseActive } = this.getTrendHistory
        if (type === 'hot') {
          if (!hotActive.title) return
          const { data } = await GetTrendHotRank({
            hotRankKeyword: hotActive.title,
          })
          this.hotList = data
          // 정리
          this.fetchMainHistory({
            id: 'trend',
            trend: {
              ...this.getTrendHistory,
              hotActive: {
                ...hotActive,
                list: data,
              },
            },
          })
        }
        // '요즘뜨는' 카테고리 검색
        else {
          const { data } = await GetTrendTheseDayRise({
            thesedayRiseId: riseActive.id,
          })
          this.riseList = data
          // 정리 중복
          this.fetchMainHistory({
            id: 'trend',
            trend: {
              ...this.getTrendHistory,
              riseActive: {
                ...riseActive,
                list: data,
              },
            },
          })
        }
      } catch (e) {
        console.log('trend rank e', e)
      } finally {
        if (clickYn) this.fetchLoading({ yn: false })
      }
    },
    handleSearch() {
      this.$tracking('home_trend_more')
      this.fetchInitChip()
      this.fetchClearResponse()
      this.$router.push('/search')
    },
    goProductPage(item: ProductItem, type: string, index: number) {
      this.$tracking(
        `home_trend_${type === 'hot' ? 'hot' : 'rising'}_${this.$numbering(
          index + 1
        )}`
      )
      this.$rendingProduct(item.productNo)
    },
    goPlanPage(item, index) {
      this.$tracking(`home_trend_plan_${this.$numbering(index + 1)}`)
      this.$router.push({
        name: 'CategoryExhibitionList',
        query: {
          promotionNo: item.link,
          categoryName: item.title,
        },
      })
    },
    isLikeChange(
      isLike: boolean,
      productNo: string,
      index: number,
      type: string
    ) {
      const { active } = this.getTrendHistory
      const activeType = active == 0 ? 'hotActive' : 'riseActive'
      const replaceList = this.getTrendHistory[activeType]
      const idx = replaceList.list.findIndex(
        (item) => item.productNo === productNo
      )
      replaceList.list[idx].isLike = isLike

      this.fetchMainHistory({
        id: 'trend',
        trend: {
          ...this.getTrendHistory,
          [activeType]: replaceList,
        },
      })

      const trackingName = `home_trend_${type}_${this.$numbering(index + 1)}`
      if (isLike) this.$tracking(`${trackingName}_like`)
      else this.$tracking(`${trackingName}_unlike`)
    },
  },
})

interface Data {
  active: {
    hot: number
    rise: number
  }
  hotList: ProductItem[]
  riseList: ProductItem[]
}
interface Methods {
  fetchClearResponse(): Promise<void>
  fetchInitChip(): Promise<void>
  handleActive(data: number): void
  handleActiveCategory(type: string, clickYn?: boolean): void
  goProductPage(item: ProductItem, type: string, index: number): void
  handleSearch(): void
  fetchLoading(yn: { yn: boolean }): void
  fetchMainHistory(payload: MainHistoryPayload): void
  setSrc(src: string): void
  setShowBridge(payload: boolean): void
  setShopId(mallId: string): void
  setProductNo(productNo: string): void
  setMallName(mailName: string): void
  setActiveCate(
    data: { id: number; title: string },
    index: number,
    type: string
  ): void
  goPlanPage(
    item: { link: string; subTitle: string; title: string },
    index: number
  ): void
  isLikeChange(
    isLike: boolean,
    productNo: string,
    index: number,
    type: string
  ): void
}
interface Computed {
  getTrendHistory: TrendInfo
  pageHistory: HomeHistory
  getPlanexHistory: PlanexItemGroup
}

interface Props {
  lazyMount: boolean
}
