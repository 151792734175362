
import Vue from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import MdPickSingle from '@/components/views/main/component/MdPickSingle.vue'
import MdPickMulti from '@/components/views/main/component/MdPickMulti.vue'
import {
  GetMdPickDisplay,
  PostAdOnClick,
  PostAdOnView,
} from '@/api/displayAPI/DisplayAPI'
import { adOnTracking } from '@/plugins/util'

export default Vue.extend<Data, Methods, Computed>({
  components: { MdPickSingle, MdPickMulti },
  filters: {
    displayComponent(type: number) {
      if (type === 1) {
        return 'MdPickSingle'
      } else {
        return 'MdPickMulti'
      }
    },
  },
  data: () => ({
    isBannerLoaded: false,
  }),
  computed: {
    ...mapGetters('HomeStore', ['getMdPickHistory']),
    ...mapGetters('BannerStore', ['getMdpickAdBottomBanner']),
    ...mapState('HomeStore', ['redirect']),
  },
  watch: {
    isLogined() {
      this.fetch()
    },
    'redirect.activeTab': {
      immediate: true,
      handler(val) {
        const { init } = this.getMdPickHistory
        if (init && val === 3) {
          this.fetch()
        }
      },
    },
  },
  methods: {
    ...mapActions('LoadingStore', ['fetchLoading']),
    ...mapActions('HomeStore', ['fetchMdPickHistory']),
    async fetch() {
      try {
        await this.fetchLoading({ yn: true })
        const { data } = await GetMdPickDisplay()
        this.fetchMdPickHistory({
          ...data,
          init: false,
        })
      } catch (e) {
        console.log('md pick list e', e)
      } finally {
        await this.fetchLoading({ yn: false })
      }
    },
    goBannerPage(link: string) {
      adOnTracking(
        this.getMdPickHistory.bottomBannerItem.bannerClickUrl,
        (key) => PostAdOnClick({ key: key })
      )

      this.$tracking(`banner_md`)
      const payload = {
        url: link,
        title: 'CELLOOK',
        isGoneBottomView: false,
      }
      this.$rendingOutsidePageOrInternal(this.$router, this.$device, payload)
    },
    onIntersect(entries: IntersectionObserverEntry[]) {
      if (!this.isBannerLoaded) return
      if (entries[0].intersectionRatio < 0.99) return

      adOnTracking(
        this.getMdPickHistory.bottomBannerItem.bannerViewEventUrl,
        (key) => PostAdOnView({ key: key })
      )
    },
    bannerLoadCheck() {
      this.isBannerLoaded = true
    },
    isLikeChange(isLike: boolean, productNo: string) {
      const { mdPickShopItemList } = this.getMdPickHistory
      const replaceList = mdPickShopItemList
      let findYn = false
      replaceList.map((groupItem, idx) => {
        if (findYn) return
        const { mdPickShopSubItemList } = groupItem
        for (const [idx2, itemList] of mdPickShopSubItemList.entries()) {
          if (itemList.productNo === productNo) {
            replaceList[idx].mdPickShopSubItemList[idx2].isLike = isLike
            findYn = true
            break
          }
        }
      })
      this.fetchMdPickHistory({
        ...this.getMdPickHistory,
        mdPickShopItemList: replaceList,
      })
    },
    isSubscribeChange(subscribeYn: boolean, shopId: string, shopType: number) {
      const { mdPickShopItemList } = this.getMdPickHistory
      const replaceList = mdPickShopItemList
      try {
        if (shopType === 1) {
          // cardType Single
          const idx = replaceList.findIndex((item) => item.shopId === shopId)
          replaceList[idx].isSubscribed = subscribeYn
          replaceList[idx].subscribeCount++
        } else {
          // cardType Multi
          replaceList.map((item, index) => {
            if (item.shopType === 2) {
              const idx = item.mdPickShopSubItemList.findIndex(
                (item2) => item2.shopId === shopId
              )
              if (idx < 0) return
              replaceList[index].mdPickShopSubItemList[idx].subscribeCount--
              replaceList[index].mdPickShopSubItemList[idx].isSubscribed =
                subscribeYn
              return
            }
          })
        }
      } catch (e) {
        console.log('md pick is subscribe api issue', e)
      } finally {
        this.fetchMdPickHistory({
          ...this.getMdPickHistory,
          mdPickShopItemList: replaceList,
        })
      }
    },
  },
})

interface Data {
  isBannerLoaded: boolean
}
interface Methods {
  fetch(): void
  fetchLoading(yn: { yn: boolean }): void
  fetchMdPickHistory(yn: MdPickHistoryPayload): void
  goBannerPage(link: string, index?: number): void
  onIntersect(entries: IntersectionObserverEntry[]): void
  bannerLoadCheck(): void
  isLikeChange(isLike: boolean, productNo: string): void
  isSubscribeChange(
    subscribeYn: boolean,
    shopId: string,
    shopType: number
  ): void
}
interface Computed {
  getMdPickHistory: MdPickHistoryPayload
  getMdpickAdBottomBanner: AdonBannerList
  redirect: {
    activeTab: number
    scroll: number[]
  }
}
