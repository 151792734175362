var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:`pb-10 ${_vm.isLogined ? 'pt-30' : 'pt-20'}`},[_c('div',{staticClass:"d-flex mx-1 px-9",on:{"click":_setup.goToShoppingLive}},[_c('div',{staticClass:"mr-auto fill-width"},[_c('div',{staticClass:"font-g20-23 campton-extrabold gray900--text"},[_c('v-img',{attrs:{"aspect-ratio":172 / 30,"src":_setup.getShoppingLiveHistory.titleImage,"contain":"","max-width":"172"}}),(!_setup.getShoppingLiveHistory.titleImage)?_c('span',[_vm._v(_vm._s(_setup.getShoppingLiveHistory.title))]):_vm._e(),(!_setup.getShoppingLiveHistory.title)?_c('v-skeleton-loader',{staticClass:"height-g20-20",attrs:{"type":"heading"}}):_vm._e()],1),_c('div',{staticClass:"font-g13-15 rixgo-regular pt-3"},[_vm._v(" "+_vm._s(_setup.getShoppingLiveHistory.subTitle)+" ")]),(!_setup.getShoppingLiveHistory.subTitle)?_c('v-skeleton-loader',{staticClass:"height-g13-13",attrs:{"type":"heading"}}):_vm._e()],1),_c('div',[_c('v-icon',{staticClass:"vector-8-18"},[_vm._v("$vuetify.icons.rightVectorBlackIcon")])],1)]),(!_setup.isLoadRecommend)?_c('div',{staticClass:"d-flex mt-10 flex-shrink-0 overflow-y-auto",staticStyle:{"height":"342px"}},[_c('v-skeleton-loader',{staticClass:"ml-10 flex-shrink-0 width-228",attrs:{"id":"shopping-live","type":"image"}}),_c('v-skeleton-loader',{staticClass:"mx-4 flex-shrink-0 width-228",attrs:{"id":"shopping-live","type":"image"}}),_c('v-skeleton-loader',{staticClass:"flex-shrink-0 width-228",attrs:{"id":"shopping-live","type":"image"}})],1):_vm._e(),(_setup.isLoadRecommend)?_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
      handler: _setup.onIntersectRecommend,
      options: {
        root: _setup.appHtml,
        rootMargin: '0px 0px 0px 0px',
        threshold: [0, 0.5, 1.0],
      },
    }),expression:"{\n      handler: onIntersectRecommend,\n      options: {\n        root: appHtml,\n        rootMargin: '0px 0px 0px 0px',\n        threshold: [0, 0.5, 1.0],\n      },\n    }"}],staticClass:"mt-10"},[_c('div',[_c(_setup.Flicking,{ref:"flicking",attrs:{"options":{
          renderOnlyVisible: false,
          align: 'prev',
          moveType: 'freeScroll',
          bound: true,
          autoInit: false,
        }},on:{"ready":_setup.ready,"move-start":_setup.onMoveStart,"move-end":_setup.onMoveEnd}},_vm._l((_setup.recommend),function(recommendItem,index){return _c('span',{key:index},[_c(_setup.ShoppingLiveCardY,{class:`width-228 mr-4 ${index === 0 ? 'pl-10' : ''}`,attrs:{"item":recommendItem,"needPlayVideo":_setup.recommendVideoPlayStatus[index]},nativeOn:{"click":function($event){return _setup.onClickShoppingLiveCardY(recommendItem)}}})],1)}),0)],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }