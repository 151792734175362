export const saleJosn = {
  topBannerItemGroup: {
    displayType: 4,
    title: '띠배너',
    rollingSecTime: 3000,
    bannerItemList: [
      {
        icon: 'https://adon-channelapi.lunasoft.co.kr/v2/Event/view?key=qFBfQriJj5Zilvaosl4kbVr5QM/Sv6KMocemWXLRnun7LR+++DXlyzaTT3bDmQwClSVAgZqD5XhXOKDJhzfhqABE9S7EgP3bnE7nX2Jplp8KsNICnZgXbhnsdrysg+yQ/5Zp8FhoWgs2GDZSRGYdni9k/mNo4E1MvkxDRR7I4bvstvnj7mqIMAhclRjvaBPJAo6SOhfGmiN46FIEVW0yqyCHFEzde9Z1+Z7GpZsOvx3+J8AvUdsbaQ==',
        link: 'lunashowa%3A%2F%2FshowaEventLink%3Ftype%3D3%26value%3D91',
        bannerClickUrl:
          'https://adon-channelapi.lunasoft.co.kr/v2/Event/click?key=qFBfQriJj5Zilvaosl4kbVr5QM/Sv6KMocemWXLRnun7LR+++DXlyzaTT3bDmQwClSVAgZqD5XhXOKDJhzfhqABE9S7EgP3bnE7nX2Jplp8KsNICnZgXbhnsdrysg+yQ/5Zp8FhoWgs2GDZSRGYdni9k/mNo4E1MvkxDRR7I4bvstvnj7mqIMAhclRjvaBPJAo6SOhfGmiN46FIEVW0yqyCHFEzde9Z1+Z7GpZsOvx3+J8AvUdsbaQ==',
        bannerViewEventUrl:
          'https://adon-channelapi.lunasoft.co.kr/v2/Event/view/event?key=qFBfQriJj5Zilvaosl4kbVr5QM/Sv6KMocemWXLRnun7LR+++DXlyzaTT3bDmQwClSVAgZqD5XhXOKDJhzfhqABE9S7EgP3bnE7nX2Jplp8KsNICnZgXbhnsdrysg+yQ/5Zp8FhoWgs2GDZSRGYdni9k/mNo4E1MvkxDRR7I4bvstvnj7mqIMAhclRjvaBPJAo6SOhfGmiN46FIEVW0yqyCHFEzde9Z1+Z7GpZsOvx3+J8AvUdsbaQ==',
      },
    ],
  },
  bestItem: {
    title: 'Sale Best',
    searchProductList: [
      {
        no: 1075,
        docId: 'doc_kcompany71_6113581579',
        docState: 'Showa',
        goodsNo: '6113581579',
        productNo: 'kcompany71_6113581579',
        nameKr: '박시체크포켓NB',
        nameEn: '',
        mallName: '피그먼트',
        categoryCode: '24 28 na na 25 na 7  showamonth',
        category1: '티&탑',
        categoryCode1: '24',
        category2: '블라우스',
        categoryCode2: '28',
        category3: 'na',
        categoryCode3: 'na',
        category4: 'na',
        categoryCode4: 'na',
        categoryNameFromMall: '블라우스/셔츠/',
        sex: null,
        age: '30',
        price: 38000,
        discountPrice: 7600,
        discountRate: 80,
        mallScore: 730,
        mallIcon:
          'https://dev-admin-img.showa.kr/store%2fmain%2fpigment_mainImg_1.png',
        mallId: '73',
        productScore: 101486,
        weekScore: 0,
        monthScore: 84755,
        newarrivalScore: 0,
        latestScore: 2021355000,
        mdOrder: 0,
        viewCnt: 0,
        saleCnt: 0,
        likeCnt: 0,
        terms: '',
        tags: '',
        showaTag1: 'na',
        showaTag2: 'na',
        colors: 'na',
        isLike: false,
        isBest: false,
        isNew: false,
        fullTerms: '',
        urlPc: 'https://smartstore.naver.com/lunasoft/products/6113581579',
        urlMobile: 'https://smartstore.naver.com/lunasoft/products/6113581579',
        imageUrl1:
          'http://shop1.phinf.naver.net/20211221_263/1640058900845IXMKA_JPEG/022001000428.jpg',
        imageUrl2: null,
        isAvailable: true,
        imageInfo: null,
        updateTime: '2022-01-17T00:00:00',
        createTime: '2021-12-21T00:00:00',
      },
      {
        no: 1076,
        docId: 'doc_kcompany71_6113586304',
        docState: 'Showa',
        goodsNo: '6113586304',
        productNo: 'kcompany71_6113586304',
        nameKr: '체크트임롱NB',
        nameEn: '',
        mallName: '피그먼트',
        categoryCode: '24 28 na na 25 na 7  showamonth',
        category1: '티&탑',
        categoryCode1: '24',
        category2: '블라우스',
        categoryCode2: '28',
        category3: 'na',
        categoryCode3: 'na',
        category4: 'na',
        categoryCode4: 'na',
        categoryNameFromMall: '블라우스/셔츠/',
        sex: null,
        age: '30',
        price: 29000,
        discountPrice: 8700,
        discountRate: 70,
        mallScore: 730,
        mallIcon:
          'https://dev-admin-img.showa.kr/store%2fmain%2fpigment_mainImg_1.png',
        mallId: '73',
        productScore: 101341,
        weekScore: 0,
        monthScore: 79731,
        newarrivalScore: 0,
        latestScore: 2021355000,
        mdOrder: 0,
        viewCnt: 0,
        saleCnt: 0,
        likeCnt: 0,
        terms: '',
        tags: '',
        showaTag1: 'na',
        showaTag2: 'na',
        colors: 'na',
        isLike: false,
        isBest: false,
        isNew: false,
        fullTerms: '',
        urlPc: 'https://smartstore.naver.com/lunasoft/products/6113586304',
        urlMobile: 'https://smartstore.naver.com/lunasoft/products/6113586304',
        imageUrl1:
          'http://shop1.phinf.naver.net/20211221_122/1640058980529oS3y2_JPEG/022001000367.jpg',
        imageUrl2: null,
        isAvailable: true,
        imageInfo: null,
        updateTime: '2022-01-17T00:00:00',
        createTime: '2021-12-21T00:00:00',
      },
      {
        no: 1106,
        docId: 'doc_kcompany71_6114027455',
        docState: 'Showa',
        goodsNo: '6114027455',
        productNo: 'kcompany71_6114027455',
        nameKr: '[모어앤모어]하우스기모MTM',
        nameEn: '',
        mallName: '피그먼트',
        categoryCode: '24 26 na na 25 na 7  showamonth',
        category1: '티&탑',
        categoryCode1: '24',
        category2: '맨투맨',
        categoryCode2: '26',
        category3: 'na',
        categoryCode3: 'na',
        category4: 'na',
        categoryCode4: 'na',
        categoryNameFromMall: '티셔츠/',
        sex: null,
        age: '30',
        price: 39000,
        discountPrice: 11700,
        discountRate: 70,
        mallScore: 730,
        mallIcon:
          'https://dev-admin-img.showa.kr/store%2fmain%2fpigment_mainImg_1.png',
        mallId: '73',
        productScore: 101143,
        weekScore: 0,
        monthScore: 69117,
        newarrivalScore: 0,
        latestScore: 2021355000,
        mdOrder: 0,
        viewCnt: 0,
        saleCnt: 0,
        likeCnt: 0,
        terms: '',
        tags: '',
        showaTag1: 'na',
        showaTag2: 'na',
        colors: 'na',
        isLike: false,
        isBest: false,
        isNew: false,
        fullTerms: '',
        urlPc: 'https://smartstore.naver.com/lunasoft/products/6114027455',
        urlMobile: 'https://smartstore.naver.com/lunasoft/products/6114027455',
        imageUrl1:
          'http://shop1.phinf.naver.net/20211221_93/1640067675394aWnuW_JPEG/056003000340.jpg',
        imageUrl2: null,
        isAvailable: true,
        imageInfo: null,
        updateTime: '2022-01-17T00:00:00',
        createTime: '2021-12-21T00:00:00',
      },
      {
        no: 1114,
        docId: 'doc_kcompany71_6114108709',
        docState: 'Showa',
        goodsNo: '6114108709',
        productNo: 'kcompany71_6114108709',
        nameKr: '골덴조거PT',
        nameEn: '',
        mallName: '피그먼트',
        categoryCode: '49 50 na na 50 na 7  showamonth',
        category1: '팬츠',
        categoryCode1: '49',
        category2: '전체',
        categoryCode2: '50',
        category3: 'na',
        categoryCode3: 'na',
        category4: 'na',
        categoryCode4: 'na',
        categoryNameFromMall: '바지/',
        sex: null,
        age: '30',
        price: 36000,
        discountPrice: 14400,
        discountRate: 60,
        mallScore: 730,
        mallIcon:
          'https://dev-admin-img.showa.kr/store%2fmain%2fpigment_mainImg_1.png',
        mallId: '73',
        productScore: 101347,
        weekScore: 0,
        monthScore: 65969,
        newarrivalScore: 0,
        latestScore: 2021355000,
        mdOrder: 0,
        viewCnt: 0,
        saleCnt: 0,
        likeCnt: 0,
        terms: '',
        tags: '',
        showaTag1: 'na',
        showaTag2: 'na',
        colors: 'na',
        isLike: false,
        isBest: false,
        isNew: false,
        fullTerms: '',
        urlPc: 'https://smartstore.naver.com/lunasoft/products/6114108709',
        urlMobile: 'https://smartstore.naver.com/lunasoft/products/6114108709',
        imageUrl1:
          'http://shop1.phinf.naver.net/20211221_115/1640069949036Km29a_JPEG/023001000329.jpg',
        imageUrl2: null,
        isAvailable: true,
        imageInfo: null,
        updateTime: '2022-01-17T00:00:00',
        createTime: '2021-12-21T00:00:00',
      },
      {
        no: 1088,
        docId: 'doc_kcompany71_6113951187',
        docState: 'Showa',
        goodsNo: '6113951187',
        productNo: 'kcompany71_6113951187',
        nameKr: '퍼플체크NB',
        nameEn: '',
        mallName: '피그먼트',
        categoryCode: '24 28 na na 25 na 7  showamonth',
        category1: '티&탑',
        categoryCode1: '24',
        category2: '블라우스',
        categoryCode2: '28',
        category3: 'na',
        categoryCode3: 'na',
        category4: 'na',
        categoryCode4: 'na',
        categoryNameFromMall: '블라우스/셔츠/',
        sex: null,
        age: '30',
        price: 30000,
        discountPrice: 12000,
        discountRate: 60,
        mallScore: 730,
        mallIcon:
          'https://dev-admin-img.showa.kr/store%2fmain%2fpigment_mainImg_1.png',
        mallId: '73',
        productScore: 101439,
        weekScore: 0,
        monthScore: 42060,
        newarrivalScore: 0,
        latestScore: 2021355000,
        mdOrder: 0,
        viewCnt: 0,
        saleCnt: 0,
        likeCnt: 0,
        terms: '',
        tags: '',
        showaTag1: 'na',
        showaTag2: 'na',
        colors: 'na',
        isLike: false,
        isBest: false,
        isNew: false,
        fullTerms: '',
        urlPc: 'https://smartstore.naver.com/lunasoft/products/6113951187',
        urlMobile: 'https://smartstore.naver.com/lunasoft/products/6113951187',
        imageUrl1:
          'http://shop1.phinf.naver.net/20211221_228/1640065568535trh6l_JPEG/022001000430.jpg',
        imageUrl2: null,
        isAvailable: true,
        imageInfo: null,
        updateTime: '2022-01-17T00:00:00',
        createTime: '2021-12-21T00:00:00',
      },
      {
        no: 410,
        docId: 'doc_kcompany71_6163978092',
        docState: 'Showa',
        goodsNo: '6163978092',
        productNo: 'kcompany71_6163978092',
        nameKr: '롱돗도체크NB',
        nameEn: '',
        mallName: '피그먼트',
        categoryCode: '24 28 na na 25 na 7  showaweek',
        category1: '티&탑',
        categoryCode1: '24',
        category2: '블라우스',
        categoryCode2: '28',
        category3: 'na',
        categoryCode3: 'na',
        category4: 'na',
        categoryCode4: 'na',
        categoryNameFromMall: '블라우스/셔츠/',
        sex: null,
        age: '30',
        price: 42000,
        discountPrice: 21000,
        discountRate: 50,
        mallScore: 730,
        mallIcon:
          'https://dev-admin-img.showa.kr/store%2fmain%2fpigment_mainImg_1.png',
        mallId: '73',
        productScore: 101085,
        weekScore: 24430,
        monthScore: 0,
        newarrivalScore: 47868,
        latestScore: 2022007000,
        mdOrder: 107479,
        viewCnt: 0,
        saleCnt: 0,
        likeCnt: 0,
        terms: '',
        tags: '',
        showaTag1: 'na',
        showaTag2:
          '30대중후 EGROUPBEST DGROUPBEST  FGROUPBEST  GGROUPBEST HGROUPBEST IGROUPBEST SHOWABESTGROUP',
        colors: 'na',
        isLike: false,
        isBest: false,
        isNew: false,
        fullTerms: '',
        urlPc: 'https://smartstore.naver.com/lunasoft/products/6163978092',
        urlMobile: 'https://smartstore.naver.com/lunasoft/products/6163978092',
        imageUrl1:
          'http://shop1.phinf.naver.net/20220107_165/1641534067015EyTbN_JPEG/022001000442.jpg',
        imageUrl2: null,
        isAvailable: true,
        imageInfo: null,
        updateTime: '2022-01-17T00:00:00',
        createTime: '2022-01-07T00:00:00',
      },
      {
        no: 501,
        docId: 'doc_kcompany71_6160883314',
        docState: 'Showa',
        goodsNo: '6160883314',
        productNo: 'kcompany71_6160883314',
        nameKr: '[모어앤모어]골덴히든NB',
        nameEn: '',
        mallName: '피그먼트',
        categoryCode: '24 28 na na 25 na 7  showaweek',
        category1: '티&탑',
        categoryCode1: '24',
        category2: '블라우스',
        categoryCode2: '28',
        category3: 'na',
        categoryCode3: 'na',
        category4: 'na',
        categoryCode4: 'na',
        categoryNameFromMall: '블라우스/셔츠/',
        sex: null,
        age: '30',
        price: 37000,
        discountPrice: 18500,
        discountRate: 50,
        mallScore: 730,
        mallIcon:
          'https://dev-admin-img.showa.kr/store%2fmain%2fpigment_mainImg_1.png',
        mallId: '73',
        productScore: 101216,
        weekScore: 11531,
        monthScore: 0,
        newarrivalScore: 1052,
        latestScore: 2022006000,
        mdOrder: 154861,
        viewCnt: 0,
        saleCnt: 0,
        likeCnt: 0,
        terms: '',
        tags: '',
        showaTag1: 'na',
        showaTag2:
          '30대중후 EGROUPBEST GGROUPBEST HGROUPBEST IGROUPBEST SHOWABESTGROUP',
        colors: 'na',
        isLike: false,
        isBest: false,
        isNew: false,
        fullTerms: '',
        urlPc: 'https://smartstore.naver.com/lunasoft/products/6160883314',
        urlMobile: 'https://smartstore.naver.com/lunasoft/products/6160883314',
        imageUrl1:
          'http://shop1.phinf.naver.net/20220106_142/16414486706212nHEr_JPEG/022003000258.jpg',
        imageUrl2: null,
        isAvailable: true,
        imageInfo: null,
        updateTime: '2022-01-17T00:00:00',
        createTime: '2022-01-06T00:00:00',
      },
      {
        no: 1091,
        docId: 'doc_kcompany71_6113952204',
        docState: 'Showa',
        goodsNo: '6113952204',
        productNo: 'kcompany71_6113952204',
        nameKr: '기모투톤롱OPS',
        nameEn: '',
        mallName: '피그먼트',
        categoryCode: '34 36 na na 35 na 7  showamonth',
        category1: '원피스',
        categoryCode1: '34',
        category2: '롱',
        categoryCode2: '36',
        category3: 'na',
        categoryCode3: 'na',
        category4: 'na',
        categoryCode4: 'na',
        categoryNameFromMall: '원피스/',
        sex: null,
        age: '30',
        price: 44000,
        discountPrice: 22000,
        discountRate: 50,
        mallScore: 730,
        mallIcon:
          'https://dev-admin-img.showa.kr/store%2fmain%2fpigment_mainImg_1.png',
        mallId: '73',
        productScore: 101321,
        weekScore: 0,
        monthScore: 97935,
        newarrivalScore: 0,
        latestScore: 2021355000,
        mdOrder: 0,
        viewCnt: 0,
        saleCnt: 0,
        likeCnt: 0,
        terms: '',
        tags: '',
        showaTag1: 'na',
        showaTag2: 'na',
        colors: 'na',
        isLike: false,
        isBest: false,
        isNew: false,
        fullTerms: '',
        urlPc: 'https://smartstore.naver.com/lunasoft/products/6113952204',
        urlMobile: 'https://smartstore.naver.com/lunasoft/products/6113952204',
        imageUrl1:
          'http://shop1.phinf.naver.net/20211221_138/1640065590166UaPWM_JPEG/024001000366.jpg',
        imageUrl2: null,
        isAvailable: true,
        imageInfo: null,
        updateTime: '2022-01-17T00:00:00',
        createTime: '2021-12-21T00:00:00',
      },
      {
        no: 1102,
        docId: 'doc_kcompany71_6113997713',
        docState: 'Showa',
        goodsNo: '6113997713',
        productNo: 'kcompany71_6113997713',
        nameKr: '[모어앤모어]베이글랍빠T',
        nameEn: '',
        mallName: '피그먼트',
        categoryCode: '24 30 na na 25 na 7  showamonth',
        category1: '티&탑',
        categoryCode1: '24',
        category2: '티셔츠',
        categoryCode2: '30',
        category3: 'na',
        categoryCode3: 'na',
        category4: 'na',
        categoryCode4: 'na',
        categoryNameFromMall: '티셔츠/',
        sex: null,
        age: '30',
        price: 24000,
        discountPrice: 12000,
        discountRate: 50,
        mallScore: 730,
        mallIcon:
          'https://dev-admin-img.showa.kr/store%2fmain%2fpigment_mainImg_1.png',
        mallId: '73',
        productScore: 101190,
        weekScore: 0,
        monthScore: 833,
        newarrivalScore: 0,
        latestScore: 2021355000,
        mdOrder: 0,
        viewCnt: 0,
        saleCnt: 0,
        likeCnt: 0,
        terms: '',
        tags: '',
        showaTag1: 'na',
        showaTag2: 'na',
        colors: 'na',
        isLike: false,
        isBest: false,
        isNew: false,
        fullTerms: '',
        urlPc: 'https://smartstore.naver.com/lunasoft/products/6113997713',
        urlMobile: 'https://smartstore.naver.com/lunasoft/products/6113997713',
        imageUrl1:
          'http://shop1.phinf.naver.net/20211221_221/1640066831445dbQ0P_JPEG/056001000457.jpg',
        imageUrl2: null,
        isAvailable: true,
        imageInfo: null,
        updateTime: '2022-01-17T00:00:00',
        createTime: '2021-12-21T00:00:00',
      },
      {
        no: 1104,
        docId: 'doc_kcompany71_6114016160',
        docState: 'Showa',
        goodsNo: '6114016160',
        productNo: 'kcompany71_6114016160',
        nameKr: '[모어앤모어]골지박시T',
        nameEn: '',
        mallName: '피그먼트',
        categoryCode: '24 30 na na 25 na 7  showamonth',
        category1: '티&탑',
        categoryCode1: '24',
        category2: '티셔츠',
        categoryCode2: '30',
        category3: 'na',
        categoryCode3: 'na',
        category4: 'na',
        categoryCode4: 'na',
        categoryNameFromMall: '티셔츠/',
        sex: null,
        age: '30',
        price: 24000,
        discountPrice: 12000,
        discountRate: 50,
        mallScore: 730,
        mallIcon:
          'https://dev-admin-img.showa.kr/store%2fmain%2fpigment_mainImg_1.png',
        mallId: '73',
        productScore: 101028,
        weekScore: 0,
        monthScore: 35211,
        newarrivalScore: 0,
        latestScore: 2021355000,
        mdOrder: 0,
        viewCnt: 0,
        saleCnt: 0,
        likeCnt: 0,
        terms: '',
        tags: '',
        showaTag1: 'na',
        showaTag2: 'na',
        colors: 'na',
        isLike: false,
        isBest: false,
        isNew: false,
        fullTerms: '',
        urlPc: 'https://smartstore.naver.com/lunasoft/products/6114016160',
        urlMobile: 'https://smartstore.naver.com/lunasoft/products/6114016160',
        imageUrl1:
          'http://shop1.phinf.naver.net/20211221_111/1640067344883U9gsb_JPEG/056001000455.jpg',
        imageUrl2: null,
        isAvailable: true,
        imageInfo: null,
        updateTime: '2022-01-17T00:00:00',
        createTime: '2021-12-21T00:00:00',
      },
      {
        no: 1208,
        docId: 'doc_kcompany71_6111347737',
        docState: 'Showa',
        goodsNo: '6111347737',
        productNo: 'kcompany71_6111347737',
        nameKr: '[모어앤모어]양기모골덴MTM',
        nameEn: '',
        mallName: '피그먼트',
        categoryCode: '24 26 na na 25 na 7  showamonth',
        category1: '티&탑',
        categoryCode1: '24',
        category2: '맨투맨',
        categoryCode2: '26',
        category3: 'na',
        categoryCode3: 'na',
        category4: 'na',
        categoryCode4: 'na',
        categoryNameFromMall: '티셔츠/',
        sex: null,
        age: '30',
        price: 42000,
        discountPrice: 21000,
        discountRate: 50,
        mallScore: 730,
        mallIcon:
          'https://dev-admin-img.showa.kr/store%2fmain%2fpigment_mainImg_1.png',
        mallId: '73',
        productScore: 101413,
        weekScore: 0,
        monthScore: 97393,
        newarrivalScore: 0,
        latestScore: 2021354000,
        mdOrder: 0,
        viewCnt: 0,
        saleCnt: 0,
        likeCnt: 0,
        terms: '',
        tags: '',
        showaTag1: 'na',
        showaTag2: 'na',
        colors: 'na',
        isLike: false,
        isBest: false,
        isNew: false,
        fullTerms: '',
        urlPc: 'https://smartstore.naver.com/lunasoft/products/6111347737',
        urlMobile: 'https://smartstore.naver.com/lunasoft/products/6111347737',
        imageUrl1:
          'http://shop1.phinf.naver.net/20211220_15/1639990520409jvKjh_JPEG/056001000459.jpg',
        imageUrl2: null,
        isAvailable: true,
        imageInfo: null,
        updateTime: '2022-01-17T00:00:00',
        createTime: '2021-12-20T00:00:00',
      },
      {
        no: 1071,
        docId: 'doc_kcompany71_6113533716',
        docState: 'Showa',
        goodsNo: '6113533716',
        productNo: 'kcompany71_6113533716',
        nameKr: '골지폴라트임T',
        nameEn: '',
        mallName: '피그먼트',
        categoryCode: '24 30 na na 25 na 7  showamonth',
        category1: '티&탑',
        categoryCode1: '24',
        category2: '티셔츠',
        categoryCode2: '30',
        category3: 'na',
        categoryCode3: 'na',
        category4: 'na',
        categoryCode4: 'na',
        categoryNameFromMall: '티셔츠/',
        sex: null,
        age: '30',
        price: 22000,
        discountPrice: 11000,
        discountRate: 50,
        mallScore: 730,
        mallIcon:
          'https://dev-admin-img.showa.kr/store%2fmain%2fpigment_mainImg_1.png',
        mallId: '73',
        productScore: 101337,
        weekScore: 0,
        monthScore: 92314,
        newarrivalScore: 0,
        latestScore: 2021355000,
        mdOrder: 0,
        viewCnt: 0,
        saleCnt: 0,
        likeCnt: 0,
        terms: '',
        tags: '',
        showaTag1: 'na',
        showaTag2: 'na',
        colors: 'na',
        isLike: false,
        isBest: false,
        isNew: false,
        fullTerms: '',
        urlPc: 'https://smartstore.naver.com/lunasoft/products/6113533716',
        urlMobile: 'https://smartstore.naver.com/lunasoft/products/6113533716',
        imageUrl1:
          'http://shop1.phinf.naver.net/20211221_69/16400580059074lxQt_JPEG/057001000584.jpg',
        imageUrl2: null,
        isAvailable: true,
        imageInfo: null,
        updateTime: '2022-01-17T00:00:00',
        createTime: '2021-12-21T00:00:00',
      },
      {
        no: 1089,
        docId: 'doc_kcompany71_6113951508',
        docState: 'Showa',
        goodsNo: '6113951508',
        productNo: 'kcompany71_6113951508',
        nameKr: '핀턱피치OPS',
        nameEn: '',
        mallName: '피그먼트',
        categoryCode: '34 35 na na 35 na 7  showamonth',
        category1: '원피스',
        categoryCode1: '34',
        category2: '전체',
        categoryCode2: '35',
        category3: 'na',
        categoryCode3: 'na',
        category4: 'na',
        categoryCode4: 'na',
        categoryNameFromMall: '원피스/',
        sex: null,
        age: '30',
        price: 41000,
        discountPrice: 20500,
        discountRate: 50,
        mallScore: 730,
        mallIcon:
          'https://dev-admin-img.showa.kr/store%2fmain%2fpigment_mainImg_1.png',
        mallId: '73',
        productScore: 101109,
        weekScore: 0,
        monthScore: 16,
        newarrivalScore: 0,
        latestScore: 2021355000,
        mdOrder: 0,
        viewCnt: 0,
        saleCnt: 0,
        likeCnt: 0,
        terms: '',
        tags: '',
        showaTag1: 'na',
        showaTag2: 'na',
        colors: 'na',
        isLike: false,
        isBest: false,
        isNew: false,
        fullTerms: '',
        urlPc: 'https://smartstore.naver.com/lunasoft/products/6113951508',
        urlMobile: 'https://smartstore.naver.com/lunasoft/products/6113951508',
        imageUrl1:
          'http://shop1.phinf.naver.net/20211221_255/16400655756612y5gq_JPEG/024001000370.jpg',
        imageUrl2: null,
        isAvailable: true,
        imageInfo: null,
        updateTime: '2022-01-17T00:00:00',
        createTime: '2021-12-21T00:00:00',
      },
      {
        no: 1096,
        docId: 'doc_kcompany71_6113953285',
        docState: 'Showa',
        goodsNo: '6113953285',
        productNo: 'kcompany71_6113953285',
        nameKr: '[모어앤모어]모직밴딩끈PT',
        nameEn: '',
        mallName: '피그먼트',
        categoryCode: '49 50 na na 50 na 7  showamonth',
        category1: '팬츠',
        categoryCode1: '49',
        category2: '전체',
        categoryCode2: '50',
        category3: 'na',
        categoryCode3: 'na',
        category4: 'na',
        categoryCode4: 'na',
        categoryNameFromMall: '바지/',
        sex: null,
        age: '30',
        price: 42000,
        discountPrice: 21000,
        discountRate: 50,
        mallScore: 730,
        mallIcon:
          'https://dev-admin-img.showa.kr/store%2fmain%2fpigment_mainImg_1.png',
        mallId: '73',
        productScore: 101107,
        weekScore: 0,
        monthScore: 80556,
        newarrivalScore: 0,
        latestScore: 2021355000,
        mdOrder: 0,
        viewCnt: 0,
        saleCnt: 0,
        likeCnt: 0,
        terms: '',
        tags: '',
        showaTag1: 'na',
        showaTag2: 'na',
        colors: 'na',
        isLike: false,
        isBest: false,
        isNew: false,
        fullTerms: '',
        urlPc: 'https://smartstore.naver.com/lunasoft/products/6113953285',
        urlMobile: 'https://smartstore.naver.com/lunasoft/products/6113953285',
        imageUrl1:
          'http://shop1.phinf.naver.net/20211221_119/1640065633713nI45d_JPEG/023001000356.jpg',
        imageUrl2: null,
        isAvailable: true,
        imageInfo: null,
        updateTime: '2022-01-17T00:00:00',
        createTime: '2021-12-21T00:00:00',
      },
      {
        no: 1179,
        docId: 'doc_kcompany71_6110081207',
        docState: 'Showa',
        goodsNo: '6110081207',
        productNo: 'kcompany71_6110081207',
        nameKr: '[모어앤모어]포체크버튼OPS',
        nameEn: '',
        mallName: '피그먼트',
        categoryCode: '34 35 na na 35 na 7  showamonth',
        category1: '원피스',
        categoryCode1: '34',
        category2: '전체',
        categoryCode2: '35',
        category3: 'na',
        categoryCode3: 'na',
        category4: 'na',
        categoryCode4: 'na',
        categoryNameFromMall: '원피스/',
        sex: null,
        age: '30',
        price: 54000,
        discountPrice: 27000,
        discountRate: 50,
        mallScore: 730,
        mallIcon:
          'https://dev-admin-img.showa.kr/store%2fmain%2fpigment_mainImg_1.png',
        mallId: '73',
        productScore: 101349,
        weekScore: 0,
        monthScore: 78422,
        newarrivalScore: 0,
        latestScore: 2021354000,
        mdOrder: 0,
        viewCnt: 0,
        saleCnt: 0,
        likeCnt: 0,
        terms: '',
        tags: '',
        showaTag1: 'na',
        showaTag2: 'na',
        colors: 'na',
        isLike: false,
        isBest: false,
        isNew: false,
        fullTerms: '',
        urlPc: 'https://smartstore.naver.com/lunasoft/products/6110081207',
        urlMobile: 'https://smartstore.naver.com/lunasoft/products/6110081207',
        imageUrl1:
          'http://shop1.phinf.naver.net/20211220_132/16399653703206xhS0_JPEG/024001000374.jpg',
        imageUrl2: null,
        isAvailable: true,
        imageInfo: null,
        updateTime: '2022-01-17T00:00:00',
        createTime: '2021-12-20T00:00:00',
      },
      {
        no: 1189,
        docId: 'doc_kcompany71_6110446447',
        docState: 'Showa',
        goodsNo: '6110446447',
        productNo: 'kcompany71_6110446447',
        nameKr: '[모어앤모어]캐주얼체크NB',
        nameEn: '',
        mallName: '피그먼트',
        categoryCode: '24 28 na na 25 na 7  showamonth',
        category1: '티&탑',
        categoryCode1: '24',
        category2: '블라우스',
        categoryCode2: '28',
        category3: 'na',
        categoryCode3: 'na',
        category4: 'na',
        categoryCode4: 'na',
        categoryNameFromMall: '블라우스/셔츠/',
        sex: null,
        age: '30',
        price: 32000,
        discountPrice: 16000,
        discountRate: 50,
        mallScore: 730,
        mallIcon:
          'https://dev-admin-img.showa.kr/store%2fmain%2fpigment_mainImg_1.png',
        mallId: '73',
        productScore: 101350,
        weekScore: 0,
        monthScore: 69340,
        newarrivalScore: 0,
        latestScore: 2021354000,
        mdOrder: 0,
        viewCnt: 0,
        saleCnt: 0,
        likeCnt: 0,
        terms: '',
        tags: '',
        showaTag1: 'na',
        showaTag2: 'na',
        colors: 'na',
        isLike: false,
        isBest: false,
        isNew: false,
        fullTerms: '',
        urlPc: 'https://smartstore.naver.com/lunasoft/products/6110446447',
        urlMobile: 'https://smartstore.naver.com/lunasoft/products/6110446447',
        imageUrl1:
          'http://shop1.phinf.naver.net/20211220_300/16399708147583HjfO_JPEG/022001000434.jpg',
        imageUrl2: null,
        isAvailable: true,
        imageInfo: null,
        updateTime: '2022-01-17T00:00:00',
        createTime: '2021-12-20T00:00:00',
      },
      {
        no: 424,
        docId: 'doc_kcompany71_6163996506',
        docState: 'Showa',
        goodsNo: '6163996506',
        productNo: 'kcompany71_6163996506',
        nameKr: '초크롱돗도NB',
        nameEn: '',
        mallName: '피그먼트',
        categoryCode: '24 28 na na 25 na 7  showaweek',
        category1: '티&탑',
        categoryCode1: '24',
        category2: '블라우스',
        categoryCode2: '28',
        category3: 'na',
        categoryCode3: 'na',
        category4: 'na',
        categoryCode4: 'na',
        categoryNameFromMall: '블라우스/셔츠/',
        sex: null,
        age: '30',
        price: 40000,
        discountPrice: 20000,
        discountRate: 50,
        mallScore: 730,
        mallIcon:
          'https://dev-admin-img.showa.kr/store%2fmain%2fpigment_mainImg_1.png',
        mallId: '73',
        productScore: 101037,
        weekScore: 73481,
        monthScore: 0,
        newarrivalScore: 11434,
        latestScore: 2022007000,
        mdOrder: 183597,
        viewCnt: 0,
        saleCnt: 0,
        likeCnt: 0,
        terms: '',
        tags: '',
        showaTag1: 'na',
        showaTag2:
          '30대중후 EGROUPBEST GGROUPBEST HGROUPBEST IGROUPBEST SHOWABESTGROUP',
        colors: 'na',
        isLike: false,
        isBest: false,
        isNew: false,
        fullTerms: '',
        urlPc: 'https://smartstore.naver.com/lunasoft/products/6163996506',
        urlMobile: 'https://smartstore.naver.com/lunasoft/products/6163996506',
        imageUrl1:
          'http://shop1.phinf.naver.net/20220107_199/1641534379566Lu9th_JPEG/022001000441.jpg',
        imageUrl2: null,
        isAvailable: true,
        imageInfo: null,
        updateTime: '2022-01-17T00:00:00',
        createTime: '2022-01-07T00:00:00',
      },
      {
        no: 426,
        docId: 'doc_kcompany71_6164060354',
        docState: 'Showa',
        goodsNo: '6164060354',
        productNo: 'kcompany71_6164060354',
        nameKr: '포체크롱OPS',
        nameEn: '',
        mallName: '피그먼트',
        categoryCode: '34 36 na na 35 na 7  showaweek',
        category1: '원피스',
        categoryCode1: '34',
        category2: '롱',
        categoryCode2: '36',
        category3: 'na',
        categoryCode3: 'na',
        category4: 'na',
        categoryCode4: 'na',
        categoryNameFromMall: '원피스/',
        sex: null,
        age: '30',
        price: 41000,
        discountPrice: 20500,
        discountRate: 50,
        mallScore: 730,
        mallIcon:
          'https://dev-admin-img.showa.kr/store%2fmain%2fpigment_mainImg_1.png',
        mallId: '73',
        productScore: 101076,
        weekScore: 74738,
        monthScore: 0,
        newarrivalScore: 6535,
        latestScore: 2022007000,
        mdOrder: 132984,
        viewCnt: 0,
        saleCnt: 0,
        likeCnt: 0,
        terms: '',
        tags: '',
        showaTag1: 'na',
        showaTag2:
          '30대중후 EGROUPBEST GGROUPBEST HGROUPBEST IGROUPBEST SHOWABESTGROUP',
        colors: 'na',
        isLike: false,
        isBest: false,
        isNew: false,
        fullTerms: '',
        urlPc: 'https://smartstore.naver.com/lunasoft/products/6164060354',
        urlMobile: 'https://smartstore.naver.com/lunasoft/products/6164060354',
        imageUrl1:
          'http://shop1.phinf.naver.net/20220107_233/1641535545668Tygts_JPEG/024001000380.jpg',
        imageUrl2: null,
        isAvailable: true,
        imageInfo: null,
        updateTime: '2022-01-17T00:00:00',
        createTime: '2022-01-07T00:00:00',
      },
      {
        no: 498,
        docId: 'doc_kcompany71_6160881772',
        docState: 'Showa',
        goodsNo: '6160881772',
        productNo: 'kcompany71_6160881772',
        nameKr: '[모어앤모어]브이넥기모MTM',
        nameEn: '',
        mallName: '피그먼트',
        categoryCode: '24 26 na na 25 na 7  showaweek',
        category1: '티&탑',
        categoryCode1: '24',
        category2: '맨투맨',
        categoryCode2: '26',
        category3: 'na',
        categoryCode3: 'na',
        category4: 'na',
        categoryCode4: 'na',
        categoryNameFromMall: '티셔츠/',
        sex: null,
        age: '30',
        price: 39000,
        discountPrice: 19500,
        discountRate: 50,
        mallScore: 730,
        mallIcon:
          'https://dev-admin-img.showa.kr/store%2fmain%2fpigment_mainImg_1.png',
        mallId: '73',
        productScore: 101445,
        weekScore: 33301,
        monthScore: 0,
        newarrivalScore: 31782,
        latestScore: 2022006000,
        mdOrder: 140162,
        viewCnt: 0,
        saleCnt: 0,
        likeCnt: 0,
        terms: '',
        tags: '',
        showaTag1: 'na',
        showaTag2:
          '30대중후 EGROUPBEST GGROUPBEST HGROUPBEST IGROUPBEST SHOWABESTGROUP',
        colors: 'na',
        isLike: false,
        isBest: false,
        isNew: false,
        fullTerms: '',
        urlPc: 'https://smartstore.naver.com/lunasoft/products/6160881772',
        urlMobile: 'https://smartstore.naver.com/lunasoft/products/6160881772',
        imageUrl1:
          'http://shop1.phinf.naver.net/20220107_119/1641543220329qJuKQ_JPEG/42679065839250003_1047233232.jpg',
        imageUrl2: null,
        isAvailable: true,
        imageInfo: null,
        updateTime: '2022-01-17T00:00:00',
        createTime: '2022-01-06T00:00:00',
      },
      {
        no: 1186,
        docId: 'doc_kcompany71_6110378916',
        docState: 'Showa',
        goodsNo: '6110378916',
        productNo: 'kcompany71_6110378916',
        nameKr: '[모어앤모어]피치포켓PT',
        nameEn: '',
        mallName: '피그먼트',
        categoryCode: '49 50 na na 50 na 7  showamonth',
        category1: '팬츠',
        categoryCode1: '49',
        category2: '전체',
        categoryCode2: '50',
        category3: 'na',
        categoryCode3: 'na',
        category4: 'na',
        categoryCode4: 'na',
        categoryNameFromMall: '바지/',
        sex: null,
        age: '30',
        price: 58000,
        discountPrice: 29000,
        discountRate: 50,
        mallScore: 730,
        mallIcon:
          'https://dev-admin-img.showa.kr/store%2fmain%2fpigment_mainImg_1.png',
        mallId: '73',
        productScore: 101460,
        weekScore: 0,
        monthScore: 63172,
        newarrivalScore: 0,
        latestScore: 2021354000,
        mdOrder: 0,
        viewCnt: 0,
        saleCnt: 0,
        likeCnt: 0,
        terms: '',
        tags: '',
        showaTag1: 'na',
        showaTag2: 'na',
        colors: 'na',
        isLike: false,
        isBest: false,
        isNew: false,
        fullTerms: '',
        urlPc: 'https://smartstore.naver.com/lunasoft/products/6110378916',
        urlMobile: 'https://smartstore.naver.com/lunasoft/products/6110378916',
        imageUrl1:
          'http://shop1.phinf.naver.net/20220107_77/1641543155243OkFRo_JPEG/42679000954905532_1040056637.jpg',
        imageUrl2: null,
        isAvailable: true,
        imageInfo: null,
        updateTime: '2022-01-17T00:00:00',
        createTime: '2021-12-20T00:00:00',
      },
    ],
  },
  percentItem: {
    filterList: [
      {
        title: '전체',
        filter: {
          'filters.maxSaleRate': '100',
          'filters.minSaleRate': '1',
        },
      },
      {
        title: '~20%',
        filter: {
          'filters.maxSaleRate': '19',
          'filters.minSaleRate': '1',
        },
      },
      {
        title: '~30%',
        filter: {
          'filters.maxSaleRate': '29',
          'filters.minSaleRate': '20',
        },
      },
      {
        title: '~40%',
        filter: {
          'filters.maxSaleRate': '39',
          'filters.minSaleRate': '30',
        },
      },
      {
        title: '~50%',
        filter: {
          'filters.maxSaleRate': '49',
          'filters.minSaleRate': '40',
        },
      },
      {
        title: '50%~',
        filter: {
          'filters.maxSaleRate': '100',
          'filters.minSaleRate': '50',
        },
      },
    ],
  },
  categoryItem: {
    filterList: [
      {
        categoryName: '상품 분류',
        filter: {
          'filters.categoryCode1': '6',
        },
      },
      {
        categoryName: '티&탑',
        filter: {
          'filters.categoryCode2': '24',
        },
      },
      {
        categoryName: '원피스',
        filter: {
          'filters.categoryCode2': '34',
        },
      },
      {
        categoryName: '아우터',
        filter: {
          'filters.categoryCode2': '8',
        },
      },
      {
        categoryName: '팬츠',
        filter: {
          'filters.categoryCode2': '49',
        },
      },
      {
        categoryName: '데님팬츠',
        filter: {
          'filters.categoryCode2': '71',
        },
      },
      {
        categoryName: '스커트',
        filter: {
          'filters.categoryCode2': '80',
        },
      },
      {
        categoryName: '액티브웨어',
        filter: {
          'filters.categoryCode2': '98',
        },
      },
      {
        categoryName: '홈/이너웨어',
        filter: {
          'filters.categoryCode2': '106',
        },
      },
      {
        categoryName: '슈즈',
        filter: {
          'filters.categoryCode2': '116',
        },
      },
      {
        categoryName: '백',
        filter: {
          'filters.categoryCode2': '131',
        },
      },
      {
        categoryName: '주얼리',
        filter: {
          'filters.categoryCode2': '145',
        },
      },
      {
        categoryName: '코디소품',
        filter: {
          'filters.categoryCode2': '153',
        },
      },
    ],
  },
  order: ['PromotionAdTop', 'Best', 'Percent', 'Category'],
}
