
import Vue from 'vue'
import { mapActions } from 'vuex'
import MdPickProduct from '@/components/common/cards/MdPickProduct.vue'
import { DeleteSubscribe, PostSubscribe } from '@/api/displayAPI/UserAPI'
import Alert from '@/components/common/dialogs/Alert.vue'
import Subscribe from '@/components/common/buttons/Subscribe.vue'

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { Subscribe, MdPickProduct, Alert },
  props: {
    mdPickItem: {
      type: Object,
      default: () => ({
        description: '',
        icon: '',
        isSubscribed: false,
        mdPickShopSubItemList: [],
        shopId: '',
        shopName: '',
        subscribeCount: 0,
      }),
    },
    gtagName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      count: [],
      alert: {
        show: false,
        message: '',
      },
    }
  },
  watch: {
    'mdPickItem.mdPickShopSubItemList'(data) {
      data.map((item: any, index: number) => {
        this.count[index] = item.subscribeCount
      })
    },
  },
  created() {
    this.mdPickItem.mdPickShopSubItemList.map((item: any, index: number) => {
      this.count[index] = item.subscribeCount
    })
  },
  methods: {
    ...mapActions('DialogStore', ['fetchLoginDialog']),
    goPage(shopId: string) {
      if (this.gtagName) this.$tracking(`${this.gtagName}_mallname`)
      this.$router.push({ name: 'StoreHome', query: { shopId } })
    },
    async handleActive(yn: boolean, shopId: string, index: number) {
      if (!this.isLogined) {
        this.fetchLoginDialog({ yn: true })
        return
      }

      yn
        ? await this.subscribe(shopId, index)
        : await this.unSubscribe(shopId, index)
    },
    async subscribe(subShopId, index) {
      if (!subShopId) return
      try {
        const { shopName, shopId, shopType } =
          this.mdPickItem.mdPickShopSubItemList[index]
        await PostSubscribe(subShopId, shopName)
        if (this.gtagName) this.$tracking(`${this.gtagName}_subscribe`)
        this.mdPickItem.mdPickShopSubItemList[index].isSubscribed = true
        this.$emit('isSubscribeChange', true, shopId, shopType)
        this.count[index] += 1
      } catch (e: any) {
        if (e && e.error_code === 400) {
          this.alert = { show: true, message: e.message }
        }
      }
    },
    async unSubscribe(subShopId, index) {
      if (!subShopId) return
      try {
        const { shopName, shopId, shopType } =
          this.mdPickItem.mdPickShopSubItemList[index]
        await DeleteSubscribe(subShopId, shopName)
        if (this.gtagName) this.$tracking(`${this.gtagName}_subscribe`)
        this.mdPickItem.mdPickShopSubItemList[index].isSubscribed = false
        this.$emit('isSubscribeChange', false, shopId, shopType)
        this.count[index] -= 1
      } catch (e) {
        console.log('mdPick unSubscribe error', e)
      }
    },
    isLikeChange(isLike: boolean, productNo: string) {
      this.$emit('isLikeChange', isLike, productNo)
    },
  },
})
interface Data {
  count: number[]
  alert: {
    show: boolean
    message: string
  }
}
interface Methods {
  goPage(shopId: string): void
  handleActive(yn: boolean, shopId: string, index: number): void
  fetchLoginDialog({ yn }: { yn: boolean }): void
  subscribe(data: string, index: number): void
  unSubscribe(data: string, index: number): void
  isLikeChange(isLike: boolean, productNo: string): void
}
interface Computed {}
interface Props {
  mdPickItem: any
  gtagName: string
}
