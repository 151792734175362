var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.isLogined ? 'pt-30' : 'pt-20'},[_c('div',{staticClass:"d-flex mx-1 px-9",on:{"click":function($event){return _vm.goPage(_vm.getBestSellerHistory.title)}}},[_c('div',{staticClass:"mr-auto fill-width"},[_c('div',{staticClass:"font-g20-23 campton-extrabold gray900--text"},[_c('v-img',{attrs:{"aspect-ratio":133 / 30,"src":_vm.getBestSellerHistory.titleImage,"contain":"","max-width":"133"}}),(!_vm.getBestSellerHistory.titleImage)?_c('span',[_vm._v(_vm._s(_vm.getBestSellerHistory.title))]):_vm._e()],1),_c('div',{staticClass:"font-g13-15 rixgo-regular pt-3"},[_vm._v(" "+_vm._s(_vm.getBestSellerHistory.subTitle)+" ")])]),_c('div',[_c('v-icon',{staticClass:"vector-8-18"},[_vm._v("$vuetify.icons.rightVectorBlackIcon")])],1)]),(_vm.getBestSellerHistory.bestSellerItemList.length > 0)?_c('div',{staticClass:"pt-10"},[_c('Slider',{attrs:{"ripple":"false","touchless":"HomeStore","sliderInName":"chip","list":_vm.getBestSellerHistory.bestSellerItemList,"itemClass":"d-flex rounded-sm mx-2","swipeClass":"noSelect","itemKey":"title","itemActiveClass":[
        'noSelect primary primary_t--text box-shadow rixgo-bold',
        'rixgo-medium light_blue gray600--text',
      ],"differentItemClass":['pl-4', '', 'pr-10'],"active":{
        yn: _vm.getBestSellerHistory.active.id !== 0,
        item: _vm.getBestSellerHistory.active,
      },"centerActive":true},on:{"handleActive":_vm.handleActive}})],1):_vm._e(),_c('div',{staticClass:"product-y-grid-two-col fill-width px-10 mt-10"},_vm._l((_vm.getBestSellerHistory.active.searchProductList.slice(
        0,
        _vm.getBestSellerHistory.limit
      )),function(item,index){return _c('div',{key:item.productNo},[_c('ProductCardY',{attrs:{"product":item,"gtagName":`home_best_${_vm.$numbering(index + 1)}`},on:{"isLikeChange":(yn, productNo) => _vm.isLikeChange(yn, productNo, index)}})],1)}),0),(
      !(
        _vm.getBestSellerHistory.active.title !== '전체' &&
        (_vm.getBestSellerHistory.limit >= _vm.maxLimit ||
          _vm.getBestSellerHistory.active.searchProductList.length <
            _vm.getBestSellerHistory.limit)
      )
    )?_c('div',{staticClass:"btn-more d-flex justify-center align-center mt-15 mx-10",on:{"click":function($event){return _vm.more(_vm.getBestSellerHistory.title)}}},[_c('span',{staticClass:"font-g13-16 campton-bold blue--text",staticStyle:{"height":"13px"}},[_vm._v(_vm._s(_vm.getBestSellerHistory.active.title === '전체' ? _vm.getBestSellerHistory.title : _vm.getBestSellerHistory.active.title))]),_c('span',{staticClass:"font-g13-16 rixgo-regular blue--text",staticStyle:{"height":"13px"}},[_vm._v(" 더보기")]),_c('img',{class:`${
        _vm.getBestSellerHistory.limit !== _vm.maxLimit && 'transform90'
      } ml-6`,staticStyle:{"height":"14px"},attrs:{"src":require("@/assets/images/arrowNextSquareBlue.svg"),"alt":"이미지"}})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }