
import Vue from 'vue'
import { mapState, mapActions, mapMutations } from 'vuex'
import { PutViewCount } from '@/api/displayAPI/ProductAPI'
import { PostRecentlyView } from '@/api/displayAPI/UserAPI'

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    item: {
      type: Object,
      default: () => ({} as BestShopItem),
    },
    gtagName: {
      type: String,
      default: '',
    },
  },
  methods: {
    ...mapActions('BridgeStore', ['fetchRendingProduct']),
    ...mapState('DeviceStore', ['deviceInfo']),
    ...mapMutations('BridgeStore', ['setShowBridgeForShop']),
    async goPage(data: ProductItem, numbering: string) {
      this.$tracking(`${this.gtagName}_${numbering}`)
      this.$emit('rendingProduct')
      try {
        if (data && data.productNo) {
          try {
            PutViewCount(data)
          } catch (e) {
            console.log(e)
          }
          if (this.isLogined) {
            try {
              PostRecentlyView({ productNo: data.productNo })
            } catch (e) {
              console.log(e)
            }
          }
          await this.fetchRendingProduct(data)
        } else {
          const { urlMobile, shopId, shopName } = this.item
          this.setShowBridgeForShop({ urlMobile, shopId, shopName })
        }
      } catch (e) {
        console.log('rending product search error', e)
      }
    },
  },
})

interface Data {}
interface Methods {
  // Store
  fetchRendingProduct(data: ProductItem): Promise<void>
  setShowBridgeForShop(data: {
    urlMobile: string
    shopId: string
    shopName: string
  }): void
  // Component
  goPage(data: ProductItem, numbering: string): void
}
interface Computed {
  deviceInfo: DeviceInfo
}
interface Props {
  item: BestShopItem
  gtagName: string
}
