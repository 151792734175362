var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"pt-30 mb-20"},[_c('div',{staticClass:"d-flex px-10",on:{"click":function($event){return _vm.goPage(_vm.getTopShopHistory.title)}}},[_c('div',{staticClass:"mr-auto fill-width"},[_c('div',{staticClass:"font-g20-23 campton-extrabold font-weight-800 gray900--text"},[_c('v-img',{attrs:{"aspect-ratio":104 / 30,"src":_vm.getTopShopHistory.titleImage,"contain":"","max-width":"104"}}),(!_vm.getTopShopHistory.titleImage)?_c('span',[_vm._v(_vm._s(_vm.getTopShopHistory.title))]):_vm._e()],1),_c('div',{staticClass:"font-g13-14 rixgo-regular gray900--text pt-3",staticStyle:{"height":"14px"}},[_vm._v(" "+_vm._s(_vm.getTopShopHistory.subTitle)+" ")])]),_c('div',[_c('v-icon',{staticClass:"vector-8-18"},[_vm._v("$vuetify.icons.rightVectorBlackIcon")])],1)]),_c('v-carousel',{attrs:{"hide-delimiters":"","show-arrows":false,"height":"auto"}},[(_vm.getTopShopHistory.topShopItemList.length > 0)?_c('VueSlickCarousel',_vm._b({attrs:{"initialSlide":_vm.initCarousel,"arrows":false},on:{"afterChange":_vm.handleActive}},'VueSlickCarousel',_vm.carouselOption,false),_vm._l((_vm.getTopShopHistory.topShopItemList),function(item,index){return _c('div',{key:item.shopId,staticClass:"px-2 pt-13"},[_c('MallBannerCard',{attrs:{"image":item.icon,"image2":item.subIcon,"title":item.mallName,"images":item.searchProductList,"chipContent":item.subscribeCount,"shopId":item.shopId,"shopName":item.shopName,"isSubscribed":item.isSubscribed,"gtagName":`home_topshop${_vm.$numbering(index + 1)}`},on:{"isLikeChange":_vm.isLikeChange}})],1)}),0):_vm._e()],1),_c('div',{staticClass:"pt-6 px-10"},[_c('v-progress-linear',{attrs:{"height":"3","background-color":"#EAEBED","color":"gray900","value":((_vm.getTopShopHistory.active + 1) /
            _vm.getTopShopHistory.topShopItemList.length) *
          100}})],1)],1),_c('section',{staticClass:"banner-container"},[(_vm.getAdvertisingBanner.bannerList.length > 0)?_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:({
        handler: _vm.onIntersect,
        options: {
          threshold: [0, 0.99, 1.0],
        },
      }),expression:"{\n        handler: onIntersect,\n        options: {\n          threshold: [0, 0.99, 1.0],\n        },\n      }"}],staticClass:"fill-width"},[_c('v-carousel',{attrs:{"hide-delimiters":"","cycle":_vm.isCompleteShowAdvertisingBannerParent,"show-arrows":false,"interval":_vm.getAdvertisingBanner.rollingSecTime * 1000,"height":"auto"},model:{value:(_vm.activeIndex),callback:function ($$v) {_vm.activeIndex=$$v},expression:"activeIndex"}},_vm._l((_vm.adBanners),function(item,index){return _c('v-carousel-item',{key:index},[_c('v-img',{attrs:{"aspect-ratio":375 / 110,"src":item.icon,"lazy-src":require('@/assets/images/img_back.png')},on:{"load":_vm.bannerImgLoad,"click":function($event){return _vm.goBannerPage(item.link, index)}}})],1)}),1)],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }