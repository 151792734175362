
import Vue from 'vue'
import ProductCardY from '@/components/common/widgets/ProductCardY.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { GetBestSellerProduct } from '@/api/displayAPI/DisplayAPI'
import Slider from '@/components/common/slider/Slider.vue'
import _ from 'lodash'

const PAGE_LIMIT = 12
const PAGE_MAX_LIMIT = 36

export default Vue.extend<Data, Methods, Computed>({
  components: { ProductCardY, Slider },
  data() {
    return {}
  },
  computed: {
    ...mapState('HomeStore', ['pageHistory', 'redirect']),
    ...mapGetters('HomeStore', ['getBestSellerHistory']),
    maxLimit() {
      return PAGE_MAX_LIMIT
    },
  },
  methods: {
    ...mapActions('LoadingStore', ['fetchLoading']),
    ...mapActions('HomeStore', ['fetchMainHistory', 'fetchSwipe']),
    async handleActive(item: BestSellerItem, index: number) {
      const { active } = this.getBestSellerHistory
      if (item.id === active.id) return

      this.fetchLoading({ yn: true })

      if (index === 0) {
        this.$tracking('home_best_cate_all')
      } else {
        this.$tracking(`home_best_cate_${this.$numbering(index + 1)}`)
      }

      await this.fetchMainHistory({
        id: 'bestSeller',
        bestSeller: {
          ...this.getBestSellerHistory,
          active: {
            ...active,
            id: item.id,
            title: item.title,
          },
          limit: PAGE_LIMIT,
        },
      })

      await this.fetchList()
    },
    async fetchList() {
      let list: ProductItem[] = []
      try {
        const { active, bestSellerItemList } = this.getBestSellerHistory

        if (active.title === '전체' || active.title === '') {
          list = _.cloneDeep(bestSellerItemList[0].searchProductList)
          return
        }

        const { data } = await GetBestSellerProduct({
          bestSellerTitle: active.title,
        })
        list = data
      } catch (e) {
        console.log('best seller select fetchList', e)
      } finally {
        this.fetchMainHistory({
          id: 'bestSeller',
          bestSeller: {
            ...this.getBestSellerHistory,
            active: {
              ...this.getBestSellerHistory.active,
              searchProductList: list,
            },
          },
        })
        this.fetchLoading({ yn: false })
      }
    },
    more(title: string) {
      if (this.getBestSellerHistory.limit === PAGE_MAX_LIMIT) {
        this.$tracking('home_best_more_02')
        return this.goPage(title)
      }
      const { active, limit } = this.getBestSellerHistory

      this.fetchMainHistory({
        id: 'bestSeller',
        bestSeller: {
          ...this.getBestSellerHistory,
          active: { ...active },
          limit: limit + PAGE_LIMIT,
        },
      })
    },
    mainSwipeHandler(data: string) {
      if (data !== 'off') {
        this.fetchSwipe({ yn: true })
      } else {
        this.fetchSwipe({ yn: false })
      }
    },
    goPage(title: string) {
      this.$tracking('home_best_more_01')

      this.$router.push({
        name: 'CategoryBestList',
        query: { categoryName: title },
      })
    },
    isLikeChange(isLike, productNo, index) {
      const replace = _.cloneDeep(this.getBestSellerHistory)
      const { active } = replace
      try {
        if (active.title === '전체' || active.title === '') {
          const idx = replace.bestSellerItemList[0].searchProductList.findIndex(
            (item) => item.productNo === productNo
          )
          replace.bestSellerItemList[0].searchProductList[idx].isLike = isLike
        } else {
          const idx = active.searchProductList.findIndex(
            (item) => item.productNo === productNo
          )
          active.searchProductList[idx].isLike = isLike
        }
      } finally {
        this.fetchMainHistory({
          id: 'bestSeller',
          bestSeller: {
            ...replace,
          },
        })
        const trackingName = `home_best_${this.$numbering(index + 1)}`

        if (isLike) this.$tracking(`${trackingName}_like`)
        else this.$tracking(`${trackingName}_unlike`)
      }
    },
  },
})

interface BestSellerItem {
  id: number
  title: string
  searchProductList: ProductItem[]
}
interface Data {}

interface Methods {
  handleActive(data: BestSellerItem, index?: number): void
  more(title: string): void
  fetchLoading(yn: { yn: boolean }): void
  fetchMainHistory(payload: MainHistoryPayload): void
  mainSwipeHandler(data: string): void
  fetchSwipe(payload: { yn: boolean }): void
  fetchList(): void
  goPage(title: string): void
  isLikeChange(isLike: boolean, productNo: string, index: number): void
}

interface Computed {
  pageHistory: HomeHistory
  getBestSellerHistory: BestSellerInfo
  maxLimit: number
}
