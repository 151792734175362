
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import Slider from '../../../common/slider/Slider.vue'

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { Slider },
  data() {
    return {
      list: [],
    }
  },
  computed: {
    ...mapState('RecentlyStore', ['recentlyProduct']),
    ...mapGetters('DisplayStore', ['getMyCollection']),
    computedList() {
      if (this.isLogined) return this.getMyCollection.searchProductList
      // 5.26 - 2.7.0 search api 사용해서 체크하는 부분으로 바뀜 추후 사용할 때, 로직 개선 필요 - recentProduct page 확인
      return JSON.parse(localStorage.getItem('recentProducts') ?? '[]')
        .filter((item: ProductItem) => {
          if (item.isAvailable) return item
        })
        .slice(0, 5)
        .map((item2: ProductItem) => {
          return { ...item2, isLike: false }
        })
    },
  },
  methods: {
    ...mapActions('HomeStore', ['fetchMainHistory', 'fetchSwipe']),

    showMore() {
      if (this.isLogined) {
        this.$router.push('/dibs')
        return
      }
      this.$router.push('/recentlyProduct')
    },
    mainSwipeHandler(data: string) {
      if (data !== 'off') {
        this.fetchSwipe({ yn: true })
      } else {
        this.fetchSwipe({ yn: false })
      }
    },
    goPage() {
      if (!this.isLogined) {
        this.$router.push('/recentlyProduct')
      } else {
        this.$router.push('/dibs')
      }
    },
  },
})

interface Data {
  list: ProductItem[]
}
interface Methods {
  showMore(): void
  mainSwipeHandler(data: string): void
  fetchSwipe(payload: { yn: boolean }): void
  goPage(): void
}
interface Computed {
  getMyCollection: {
    displayType: number
    title: string
    subTitle: string
    searchProductList: Array<ProductItem>
  }
  computedList: ProductItem[]
  recentlyProduct: ProductItem[]
}
interface Props {}
