var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.isLogined ? 'pt-20' : 'pt-30'},[_c('div',{staticClass:"d-flex mb-10 px-10",on:{"click":function($event){return _vm.more()}}},[_c('div',{staticClass:"mr-auto fill-width"},[(_vm.getNewArrivalsHistory.title)?_c('div',{staticClass:"font-g20-23 campton-extrabold font-weight-800 gray900--text"},[_c('v-img',{attrs:{"aspect-ratio":157 / 30,"src":_vm.getNewArrivalsHistory.titleImage,"contain":"","max-width":"157"}}),(!_vm.getNewArrivalsHistory.titleImage)?_c('span',[_vm._v(_vm._s(_vm.getNewArrivalsHistory.title))]):_vm._e()],1):_vm._e(),(_vm.getNewArrivalsHistory.subTitle)?_c('div',{staticClass:"font-g13-14 rixgo-regular pt-3"},[_vm._v(" "+_vm._s(_vm.getNewArrivalsHistory.subTitle)+" ")]):_vm._e()]),_c('div',{staticClass:"d-inline-block font-g11-12 campton-semibold blue--text border-radius-15 height-30 pb-2 pt-3 px-4 mr-7",staticStyle:{"border":"1px solid #003399"}},[_c('span',[_vm._v(_vm._s(_vm._f("handleTransform")(_vm.getNewArrivalsHistory.displayCount)))])]),_c('div',[_c('v-icon',{staticClass:"vector-8-18"},[_vm._v("$vuetify.icons.rightVectorBlackIcon")])],1)]),(_vm.getNewArrivalsHistory.newArrivalsItemList.length > 0)?_c('Slider',{attrs:{"useNotfirst":true,"touchless":"HomeStore","sliderInName":"mall","firstSlot":true,"list":_vm.getNewArrivalsHistory.newArrivalsItemList,"lastSlot":true,"swipeSliderClass":"mx-1","active":{ yn: true, item: _vm.getNewArrivalsHistory.active },"mallProps":{ activeEffect: true }},on:{"handleActive":_vm.handleActive}},[_c('div',{staticClass:"relative ml-9",attrs:{"slot":"first"},slot:"first"},[_c('div',{class:_vm.getNewArrivalsHistory.active.id === 0
            ? 'pa-1 mall-border-active'
            : 'pa-1 mall-border'},[_c('div',{staticStyle:{"width":"60px","height":"60px","border-radius":"24px","border":"1px solid #eaebed","overflow":"hidden"}},[_c('div',{staticClass:"fill-width fill-height white"},[_c('div',{staticClass:"font-g16-24 gray900--text relative text-center transform-50 top-50 left-50"},[_c('div',{staticClass:"rixgo-bold"},[_vm._v("ALL")])])])])]),_c('div',{staticClass:"text-center font-g12-18 text-center mt-3",class:_vm.getNewArrivalsHistory.active.id === 0
            ? 'rixgo-bold black--text'
            : 'rixgo-regular gray500--text'},[_vm._v(" ITEMS ")])]),_c('div',{staticClass:"relative ml-2 mr-20 mt-1",attrs:{"slot":"last"},slot:"last"},[_c('div',{staticStyle:{"width":"60px","height":"60px","border-radius":"24px"}},[_c('div',{staticClass:"font-g12-18 white--text relative text-center transform-50 top-50 left-50",on:{"click":function($event){return _vm.goPage()}}},[_c('v-icon',{attrs:{"size":"24"}},[_vm._v("$vuetify.icons.arrowRightIcon")])],1)]),_c('div',{staticClass:"text-center font-g12-18 text-center mt-3 rixgo-regular gray500--text"},[_vm._v(" 구독몰 ")])])]):_vm._e(),_c('div',{staticClass:"product-y-grid-three-col pt-10 px-10"},_vm._l((_vm.getNewArrivalsHistory.searchProductList.slice(
        0,
        9
      )),function(item,index){return _c('div',{key:item.productNo},[_c('ProductCardY',{attrs:{"product":item,"oneLine":true,"color":"red","onDiscount":false,"fonts":['rixgo-bold', 'rixgo-regular', 'campton-medium'],"gtagName":`home_new_${_vm.$numbering(index + 1)}`,"aspectRatio":109 / 136,"priceSmallPaddingTop":""},on:{"isLikeChange":_vm.isLikeChange}})],1)}),0),_c('ButtonMore',{staticClass:"mx-10",attrs:{"buttonTitle":_vm.getNewArrivalsHistory.active.shopName
        ? _vm.getNewArrivalsHistory.active.shopName
        : _vm.getNewArrivalsHistory.title,"isStore":!!_vm.getNewArrivalsHistory.active.id},on:{"more":function($event){return _vm.more()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }