
import Vue, { PropType } from 'vue'
import { mapActions, mapState } from 'vuex'
import Lottie from '@/components/common/lottie/LottieView.vue'
import { AnimationItem } from 'lottie-web'
import { DeleteLikeProduct, PostLikeProduct } from '@/api/displayAPI/UserAPI'
import Alert from '@/components/common/dialogs/Alert.vue'
import { destroyLottieAnim } from '@/plugins/util/lottie'

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'MdPickProduct',
  components: { Lottie, Alert },
  props: {
    /**
     * MdPick Item < Props >
     * image : 카드에 사용할 이미지
     * badge : 배지 사용 여부
     * chip : 칩 사용 여부
     * chipContent : 칩 내용 ( TODO : 이거 하나로 사용해도 될 듯 )
     * badgeX : 배지 가로 컨트롤
     * badgeY : 배지 세로 컨트롤
     * aspectRatio : 이미지 비율
     * imageInInfo : 이미지 안에 정보 노출 여부
     */
    mdPickItem: {
      type: Object as PropType<MdPickShopSubItem>,
      default: {} as MdPickShopSubItem,
    },
    badge: {
      type: Boolean,
      default: false,
    },
    badgeX: {
      type: String,
      default: () => '28',
    },
    badgeY: {
      type: String,
      default: () => '30',
    },
    aspectRatio: {
      type: Number,
      default: () => 165 / 190,
    },
    gtagName: {
      type: String,
      default: '',
    },
    /**
     * 컴포넌트 크기 고정여부 true 면 109px 로 고정됨
     */
    isFixSize: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: this.mdPickItem.isLike ?? false,
      anim: null,
      alert: {
        show: false,
        message: '',
      },
    }
  },
  computed: {
    ...mapState('PullToRefreshStore', ['refreshNoti']),
    cardStyle() {
      return `width:100%;height:100%;`
    },
  },
  watch: {
    isLogined() {
      this.isLikeChecked()
    },
    refreshNoti() {
      this.isLikeChecked()
    },
    'mdPickItem.isLike'() {
      this.isLikeChecked()
    },
  },
  beforeDestroy() {
    destroyLottieAnim(this.anim)
  },
  methods: {
    ...mapActions('DialogStore', ['fetchLoginDialog']),
    isLikeChecked() {
      this.checked = this.mdPickItem.isLike ?? false
      this.playLottie(100)
    },
    async onChange() {
      if (!this.isLogined) {
        // 로그인창 오픈
        this.fetchLoginDialog({ yn: true })
        return
      }
      try {
        this.checked ? await this.unlikeProduct() : await this.likeProduct()
      } catch (error) {
        console.log(error)
      }
      this.playLottie(1)
    },
    playLottie(speed: number) {
      if (!this.anim) {
        return
      }
      if (this.checked) {
        this.anim.setSpeed(speed)
        this.anim.play()
        return
      } else {
        this.anim.setSpeed(-1 * speed)
        this.anim.play()
        return
      }
    },
    goPage() {
      if (this.gtagName) {
        this.$tracking(this.gtagName)
      }
      this.$rendingProduct(this.mdPickItem.productNo)
    },
    handleAnim(anim: AnimationItem | null) {
      this.anim = anim
    },
    async likeProduct() {
      try {
        window.kakaoPixel.addToWishList({ id: this.mdPickItem.productNo })
        const { productNo, discountPrice, nameKr } = this.mdPickItem
        this.$kakaoPixel('addToWishList', { id: productNo })

        this.$criteo({
          event: 'addToCart',
          item: [{ id: productNo, price: discountPrice, quantity: '1' }],
        })
        await PostLikeProduct(this.mdPickItem)
        this.$emit('isLikeChange', true, productNo)
        if (this.gtagName) this.$tracking(`${this.gtagName}_like`)
        this.checked = true
      } catch (e: any) {
        if (e && e.error_code === 400) {
          this.alert = { show: true, message: e.message }
        }
      }
    },
    async unlikeProduct() {
      try {
        await DeleteLikeProduct([this.mdPickItem])
        this.$emit('isLikeChange', false, this.mdPickItem.productNo)
        if (this.gtagName) this.$tracking(`${this.gtagName}_unlike`)
        this.checked = false
      } catch (e) {
        console.log(e)
      }
    },
  },
})

interface Data {
  checked: boolean
  anim: AnimationItem | null
  alert: {
    show: boolean
    message: string
  }
}
interface Methods {
  fetchLoginDialog({ yn }: { yn: boolean }): void
  onChange(data: string): void
  goPage(): void
  handleAnim(anim: AnimationItem | null): void
  likeProduct(): void
  unlikeProduct(): void
  playLottie(speed: number): void
  isLikeChecked(): void
}
interface Computed {
  cardStyle: string
  refreshNoti: boolean
}
interface Props {
  mdPickItem: MdPickShopSubItem
  badge: boolean
  badgeX: string
  badgeY: string
  aspectRatio: number
  gtagName: string
  isFixSize: boolean
}
