
import Vue, { PropType } from 'vue'
import { mapActions, mapState } from 'vuex'
import { DeleteSubscribe, PostSubscribe } from '@/api/displayAPI/UserAPI'
import Alert from '@/components/common/dialogs/Alert.vue'

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { Alert },
  props: {
    item: {
      type: Object as PropType<Shop | ShopInfo>,
      default: {} as Shop | ShopInfo,
    },
    gtagName: {
      type: String,
      default: '',
    },
    contentSize: {
      type: String,
      default: '60%',
    },
    // 나중에 이거 제거
    bestStore: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: this.item.isSubscribed,
      alert: {
        show: false,
        message: '',
      },
    }
  },
  computed: {
    ...mapState('PullToRefreshStore', ['refreshNoti']),
  },
  watch: {
    'item.isSubscribed'(yn) {
      this.active = yn
    },
    refreshNoti() {
      this.active = this.item.isSubscribed
    },
    isSubscribed() {
      this.active = this.item.isSubscribed
    },
  },
  methods: {
    ...mapActions('DialogStore', ['fetchLoginDialog']),
    async subscribe() {
      try {
        if (this.gtagName) {
          this.$tracking(`${this.gtagName}_subscribe`)
        }

        await PostSubscribe(this.item.shopId, this.item.shopName)
        this.active = true
        this.$emit('onChangeSubscribe', true, this.item.shopId)
      } catch (e: any) {
        if (e && e.error_code === 400) {
          this.alert = { show: true, message: e.message }
        }
      }
    },
    async unSubscribe() {
      try {
        if (!this.item.shopId) {
          return
        }
        if (this.gtagName) {
          this.$tracking(`${this.gtagName}_unsubscribe`)
        }
        await DeleteSubscribe(this.item.shopId, this.item.shopName)
        this.active = false
        this.$emit('onChangeSubscribe', false, this.item.shopId)
      } catch (e) {
        console.log(e)
      }
    },
    async handleSubscribed() {
      if (this.isLogined) {
        this.active ? await this.unSubscribe() : await this.subscribe()
      } else {
        this.fetchLoginDialog({ yn: true })
      }
    },
    goPage() {
      if (this.gtagName) {
        this.$tracking(this.gtagName)
      }
      this.$router.push({
        name: 'StoreHome',
        query: { shopId: this.item.shopId },
      })
    },
  },
})

interface Data {
  active: boolean
  alert: {
    show: boolean
    message: string
  }
}
interface Methods {
  fetchLoginDialog({ yn }: { yn: boolean }): void
  handleSubscribed(): void
  subscribe(): void
  unSubscribe(): void
  goPage(): void
}
interface Computed {
  refreshNoti: boolean
}
interface Props {
  item: Shop | ShopInfo
  gtagName: string
  contentSize: string
  bestStore: boolean
}
