
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import ProductCardY from '@/components/common/widgets/ProductCardY.vue'
import {
  GetSaleDisplay,
  PostAdOnClick,
  PostAdOnView,
} from '@/api/displayAPI/DisplayAPI'
import { GetSearch } from '@/api/searchAPI/SearchAPI'
import { saleJosn } from '@/util/sale'
import Slider from '@/components/common/slider/Slider.vue'
import { AppProfile } from '../../../../types/App'
import { adOnTracking } from '@/plugins/util'

const DEFAULT_PAGE = 0
const DEFAULT_LIMIT = 50
const SALE_PRODUCT_MAX_COUNT = 200
const DEFAULT_CARD_HEIGHT = 297 //디자인 상 상품 카드 높이

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Sale',
  components: {
    Slider,
    ProductCardY,
  },
  props: {
    onBanner: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      list: [],
      saleInfo: saleJosn,
      apiCall: false,
      bannerIndex: 0,
      touchless: false,
      onSticky: false,
      pagingThreshold: DEFAULT_CARD_HEIGHT, // 디자인 상품 카드 높이
    }
  },
  mounted() {
    Vue.nextTick(() => {
      const product = this.$refs.product as HTMLDivElement[]

      /**
       * 상품 카드 크기는 디바이스마다 다르므로
       * 카드 높이 구해지면 카드 높이로 세팅
       * 1개의 ROW 에 2개의 상품이 전시 되므로 상품 1개의 높이는 DEFAULT_LIMIT(50)개 중 1개의 높이는 20%
       */
      this.pagingThreshold = product?.[0]?.offsetHeight ?? DEFAULT_CARD_HEIGHT
    })
  },
  watch: {
    isLogined() {
      this.fetch()
    },
    'redirect.activeTab': {
      immediate: true,
      handler(val) {
        const { init } = this.getSaleHistory
        if (val === 4 && init) {
          this.fetch()
        } else {
          const { searchProductList } = this.getSaleHistory
          this.list = searchProductList
          this.apiCall = true
        }
      },
    },
  },
  computed: {
    ...mapState('HomeStore', ['redirect']),
    ...mapGetters('HomeStore', ['getSaleHistory']),
    ...mapGetters('BannerStore', ['getSaleSectionAdTopBanner']),
    ...mapState('BannerStore', ['saleBanner']),
    ...mapState('ProfileStore', ['profile']),
    percentTrackingParams(): string[] {
      return [
        'sale_percent_all',
        'sale_percent_20',
        'sale_percent_30',
        'sale_percent_40',
        'sale_percent_50',
        'sale_percent_50_upper',
      ]
    },
    /**
     * 카테고리는 순서, 내용 모두 변경될 여지가 있어 서버에서 내려받아야 하나 당장적용이 어려워 하드코드
     * 추후 서버에서 파라미터이름을 내려 받아야 합니다.
     */
    categoryTrackingParams(): { eventName: string; categoryCode: string }[] {
      return [
        { eventName: 'sale_cate_all', categoryCode: '7' },
        { eventName: 'sale_cate_outer', categoryCode: '8' },
        { eventName: 'sale_cate_teetop', categoryCode: '24' },
        { eventName: 'sale_cate_skirt', categoryCode: '80' },
        { eventName: 'sale_cate_ops', categoryCode: '34' },
        { eventName: 'sale_cate_pants', categoryCode: '49' },
        { eventName: 'sale_cate_denimpants', categoryCode: '71' },
        { eventName: 'sale_cate_activewear', categoryCode: '98' },
        { eventName: 'sale_cate_homeinner', categoryCode: '106' },
        { eventName: 'sale_cate_shoes', categoryCode: '116' },
        { eventName: 'sale_cate_bag', categoryCode: '131' },
        { eventName: 'sale_cate_jewelry', categoryCode: '145' },
        { eventName: 'sale_cate_cody', categoryCode: '153' },
      ]
    },
    headerAnimClass(): string {
      if (this.onBanner) {
        return 'down-sticky-bar'
      } else {
        return 'up-sticky-bar'
      }
    },
  },
  methods: {
    ...mapActions('HomeStore', ['fetchSaleHistory', 'fetchSwipe']),
    ...mapActions('LoadingStore', ['fetchLoading']),
    ...mapActions('ProfileStore', ['fetchGetProfile']),
    async fetch() {
      try {
        await this.fetchLoading({ yn: true })
        const { data } = await GetSaleDisplay()
        await this.fetchSaleHistory({
          ...this.getSaleHistory,
          init: false,
          bestItem: { ...data.bestItem },
          activeRate: { ...data.percentItem.filterList[0] },
          activeCate: { ...data.categoryItem.filterList[0] },
          categoryItem: { ...data.categoryItem },
          percentItem: { ...data.percentItem },
        })
        this.search()
      } catch (e) {
        console.log('sale list e', e)
      } finally {
        this.apiCall = true
        this.fetchLoading({ yn: false })
      }
    },
    async search(type) {
      try {
        const { paging, activeRate, activeCate, searchProductList } =
          this.getSaleHistory

        const params: SearchParams = {
          ...paging,
          ...activeRate.filter,
          'filters.categoryCode': activeCate.categoryCode ?? '7',
        }

        const { data } = await GetSearch({ ...params })
        const resSearchProductList = data.list ? data.list : []
        if (type === 'page') {
          this.list = searchProductList.concat(resSearchProductList)
        } else {
          this.list = resSearchProductList
        }
      } catch (e) {
        console.log('sale search error')
      } finally {
        await this.fetchSaleHistory({
          ...this.getSaleHistory,
          init: false,
          searchProductList: this.list,
        })
        await this.fetchLoading({ yn: false })
      }
    },
    goPage() {
      this.$tracking('sale_best_bestseller')
      this.$router.push({
        name: 'CategoryBestList',
        query: {
          categoryName: 'Best Seller',
        },
      })
    },
    async onRateActive(active: PercentItem, index: number) {
      this.fetchLoading({ yn: true })
      const { offsetHeight } = this.$refs.bestItemContainer as HTMLDivElement
      this.$getAppHtml.scrollTo({ top: offsetHeight })

      this.$tracking(this.percentTrackingParams[index])
      await this.fetchSaleHistory({
        ...this.getSaleHistory,
        activeRate: active,
        paging: {
          'paging.page': DEFAULT_PAGE,
          'paging.limit': DEFAULT_LIMIT,
        },
      })
      await this.search()
    },
    async onCateActive(active: CategoryItem) {
      this.fetchLoading({ yn: true })
      const { offsetHeight } = this.$refs.bestItemContainer as HTMLDivElement
      this.$getAppHtml.scrollTo({ top: offsetHeight })

      const eventName = this.categoryTrackingParams.find((value) => {
        return active.categoryCode === value.categoryCode
      })?.eventName

      if (eventName) {
        this.$tracking(eventName)
      }

      await this.fetchSaleHistory({
        ...this.getSaleHistory,
        activeCate: active,
        paging: {
          'paging.page': DEFAULT_PAGE,
          'paging.limit': DEFAULT_LIMIT,
        },
      })
      await this.search()
    },
    mainSwipeHandler(data: string) {
      if (data !== 'off') {
        this.fetchSwipe({ yn: true })
      } else {
        this.fetchSwipe({ yn: false })
      }
    },
    goBannerPage(link: string) {
      adOnTracking(
        this.getSaleSectionAdTopBanner.bannerList[this.bannerIndex]
          .bannerClickUrl,
        (key) => PostAdOnClick({ key: key })
      )

      this.$tracking(`banner_sale`)
      const payload = {
        url: link,
        title: 'CELLOOK',
        isGoneBottomView: false,
      }
      this.$rendingOutsidePageOrInternal(this.$router, this.$device, payload)
    },
    handleRefresh() {
      this.fetch()
      this.fetchGetProfile()
    },
    onIntersect(entries: IntersectionObserverEntry[]) {
      if (this.$getAppHtml.scrollTop != 0) return
      if (entries[0].intersectionRatio < 0.99) return

      adOnTracking(
        this.getSaleSectionAdTopBanner.bannerList[this.bannerIndex]
          .bannerViewEventUrl,
        (key) => PostAdOnView({ key: key })
      )
    },
    handleActive(item: any, index: number) {
      this.$tracking(`sale_best_${this.$numbering(index + 1)}`)
    },
    bestSectionIsLikeChange(yn: boolean, id: string, index: number) {
      const copy = { ...this.getSaleHistory }
      copy.bestItem.searchProductList[index].isLike = yn
      this.fetchSaleHistory(copy)

      const trackingText = yn
        ? `sale_best_${this.$numbering(index + 1)}_like`
        : `sale_best_${this.$numbering(index + 1)}_unlike`
      this.$tracking(trackingText)
    },
    pullToState(yn) {
      this.touchless = yn
    },
    onIntersectList(entries: IntersectionObserverEntry[]) {
      //페이징
      if (this.redirect.activeTab !== 4) return

      const { paging } = this.getSaleHistory
      // Sale Product 개수 최대 200개만 노출
      if (this.list.length >= SALE_PRODUCT_MAX_COUNT) return

      const bottomOfWindow = entries[0].isIntersecting

      if (bottomOfWindow) {
        this.fetchSaleHistory({
          ...this.getSaleHistory,
          paging: { ...paging, 'paging.page': paging['paging.page'] + 1 },
        })

        this.search('page')
      }
    },
    isLikeChange(yn: boolean, id: string, index: number) {
      const trackingText = yn
        ? `sale_${this.$numbering(index + 1)}_like`
        : `sale_${this.$numbering(index + 1)}_unlike`
      this.$tracking(trackingText)
    },
  },
})

interface Data {
  list: ProductItem[]
  saleInfo: any
  apiCall: boolean
  bannerIndex: number
  touchless: boolean
  onSticky: boolean
  pagingThreshold: number
}
interface Methods {
  goPage(data: string): void
  onRateActive(data: PercentItem, index: number): void
  onCateActive(data: CategoryItem): void
  fetch(): void
  search(type?: string): void
  fetchLoading(yn: { yn: boolean }): void
  fetchSaleHistory(payload: SaleHistoryPayload): void
  mainSwipeHandler(data: string): void
  fetchSwipe(payload: { yn: boolean }): void
  goBannerPage(link: string, index?: number): void
  handleRefresh(): void
  fetchGetProfile(): Promise<void>
  onIntersect(entries: IntersectionObserverEntry[]): void
  handleActive(item: any, index: number): void
  bestSectionIsLikeChange(yn: boolean, id: string, index: number): void
  pullToState(yn: boolean): void
  onIntersectList(entries: IntersectionObserverEntry[]): void
  isLikeChange(yn: boolean, id: string, index: number): void
}
interface Computed {
  saleBanner: boolean
  getSaleHistory: SaleHistoryPayload
  redirect: { activeTab: number; scroll: number[] }
  profile: AppProfile
  percentTrackingParams: string[]
  categoryTrackingParams: { eventName: string; categoryCode: string }[]
  getSaleSectionAdTopBanner: AdonBannerList
  headerAnimClass: string
}
interface Props {
  onBanner: boolean
}
