
import Vue from 'vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import ProductCardY from '@/components/common/widgets/ProductCardY.vue'
import { GetAllItemsDisplay } from '@/api/displayAPI/DisplayAPI'

export default Vue.extend<Data, Methods, Computed>({
  components: { ProductCardY },
  data: () => ({
    page: 0,
    hasNext: true,
    list: [],
    scroll: 0,
  }),
  created() {
    const { init, searchProductList, paging } = this.getAllItemsHistory

    if (!init) {
      this.list = searchProductList
      this.page = paging.page + 1
      this.hasNext = paging.hasNext
    }
  },
  watch: {
    async isLogined() {
      await this.fetch()

      this.$getAppHtml.scrollTo({ top: this.scroll })
      this.scroll = 0
    },
    'redirect.activeTab': {
      immediate: true,
      handler(val) {
        const { init } = this.getAllItemsHistory
        if (val == 2 && init) {
          this.refresh()
        }
      },
    },
    getLoginDialog(isDialogOpen) {
      if (isDialogOpen) {
        this.scroll = this.$getAppHtml.scrollTop
      }
    },
  },
  computed: {
    ...mapGetters('HomeStore', ['getAllItemsHistory']),
    ...mapState('HomeStore', ['redirect']),
    ...mapGetters('DialogStore', ['getLoginDialog']),
  },
  methods: {
    ...mapActions('LoadingStore', ['fetchLoading']),
    ...mapActions('HomeStore', ['fetchAllItemsHistory']),

    onIntersect(entries: IntersectionObserverEntry[]) {
      //초기 로딩아니면서 페이지 하단으로 스크롤 되었을때
      const needLoadMore =
        entries[0].isIntersecting && this.hasNext && this.list.length > 0
      if (needLoadMore) {
        this.fetch()
      }
    },
    async refresh() {
      this.page = 0
      this.list = []
      this.hasNext = true
      const data = this.getAllItemsHistory
      data.searchProductList = []
      this.fetchAllItemsHistory({ ...data })

      await this.fetch()
    },
    async fetch() {
      try {
        this.fetchLoading({ yn: true })

        const { data } = await GetAllItemsDisplay({ page: this.page })

        //스토어에 API 아이템 교체가 아닌 추가 되도록 조치
        // this.list.push(...data.searchProductList)

        this.list = [...this.list, ...data.searchProductList]

        this.hasNext = data.paging.hasNext // 필요없는 API 콜 방지

        data.searchProductList = this.list

        //캐시 추가
        this.fetchAllItemsHistory({
          ...data,
          init: false,
        })

        //항상 API 호출 후에 page++
        this.page++
      } catch (e) {
        console.log('all items list err')
      } finally {
        this.fetchLoading({ yn: false })
      }
    },
    handleRouter() {
      if (this.isLogined) {
        this.$tracking('Items_bestseller')
        this.$router.push({
          name: 'CategoryBestList',
          query: { categoryName: 'BEST SELLER' },
        })
      } else {
        this.$tracking('Items_newarrivals')
        this.$router.push({
          name: 'CategoryNewList',
          query: { categoryName: 'NEW ARRIVALS' },
        })
      }
    },
    isLikeChange(isLike: boolean, productNo: string, index: number) {
      const { searchProductList } = this.getAllItemsHistory
      const replaceList = searchProductList
      const idx = replaceList.findIndex((item) => item.productNo === productNo)
      replaceList[idx].isLike = isLike
      this.fetchAllItemsHistory({
        ...this.getAllItemsHistory,
        searchProductList: replaceList,
      })
      const trackingName = `Items_${this.$numbering(index + 1)}`
      if (isLike) this.$tracking(`${trackingName}_like`)
      else this.$tracking(`${trackingName}_unlike`)
    },
  },
})

interface Data {
  list: ProductItem[]
  page: number
  hasNext: boolean
  scroll: number
}
interface Methods {
  fetch(): Promise<void>
  fetchLoading(yn: { yn: boolean }): void
  handleRouter(): void
  fetchAllItemsHistory(payload: AllItemsHistoryPayload): void
  isLikeChange(isLike: boolean, productNo: string, index: number): void
  onIntersect(entries: IntersectionObserverEntry[]): void
  refresh(): void
}
interface Computed {
  getAllItemsHistory: AllItemsHistoryPayload
  redirect: {
    activeTab: number
    scroll: number[]
  }
  getLoginDialog: boolean
}
