var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pt-20 px-10"},[_c('div',{staticClass:"d-flex mb-6",on:{"click":function($event){return _vm.handleSearch()}}},[_c('div',{staticClass:"mr-auto fill-width"},[_c('div',{staticClass:"font-g20-23 campton-extrabold font-weight-800 gray900--text"},[_c('v-img',{attrs:{"aspect-ratio":85 / 30,"src":_vm.getTrendHistory.titleImage,"contain":"","max-width":"85"}}),(!_vm.getTrendHistory.titleImage)?_c('span',[_vm._v(_vm._s(_vm.getTrendHistory.title))]):_vm._e()],1),_c('div',{staticClass:"font-g13-14 rixgo-regular gray900--text pt-3"},[_vm._v(" "+_vm._s(_vm.getTrendHistory.subTitle)+" ")])]),_c('div',[_c('v-icon',{staticClass:"vector-8-18"},[_vm._v("$vuetify.icons.rightVectorBlackIcon")])],1)]),_c('div',{staticClass:"fill-width pt-10"},[_c('v-tabs',{staticClass:"font-g15-16",staticStyle:{"margin-left":"-5%"},attrs:{"height":"37","color":"gray900"},model:{value:(_vm.getTrendHistory.active),callback:function ($$v) {_vm.$set(_vm.getTrendHistory, "active", $$v)},expression:"getTrendHistory.active"}},[_vm._l((_vm.getTrendHistory.trendButtonList),function(item,index){return _c('v-tab',{key:index,staticStyle:{"display":"none"}},[_vm._v(_vm._s(item))])}),_vm._l((_vm.getTrendHistory.trendButtonList),function(item,index){return _c('div',{key:item + index,staticClass:"pl-10",on:{"click":function($event){return _vm.handleActive(index)}}},[_c('div',{class:`font-g15-16 rixgo-bold ${
            _vm.getTrendHistory.active === index
              ? 'gray900--text'
              : 'gray400--text'
          }`},[_vm._v(" "+_vm._s(item)+" ")]),(_vm.getTrendHistory.active === index)?_c('div',{staticClass:"pt-4",staticStyle:{"border-bottom":"2px solid black"}}):_vm._e()])})],2),_c('v-tabs-items',{staticClass:"pt-5",model:{value:(_vm.getTrendHistory.active),callback:function ($$v) {_vm.$set(_vm.getTrendHistory, "active", $$v)},expression:"getTrendHistory.active"}},[_c('v-tab-item',[_c('div',{staticClass:"d-flex align-content-start flex-wrap"},_vm._l((_vm.getTrendHistory.trendHotRankKeywordList),function(item,index){return _c('span',{key:item.id,class:`chip font-g13-13 rixgo-medium ${
              _vm.active.hot === item.id
                ? 'blue white--text box-shadow rixgo-bold'
                : 'light_blue gray600--text'
            } px-7 py-6 mr-4 mb-4`,on:{"click":function($event){return _vm.setActiveCate(item, index, 'hot')}}},[_vm._v(_vm._s(item.title))])}),0),_c('div',{staticClass:"d-flex pt-10 flex-wrap"},_vm._l((_vm.hotList.slice(0, 9)),function(item,index){return _c('div',{key:item.productNo,staticClass:"width-33 relative",staticStyle:{"padding":"0.23%"}},[_c('Product',{attrs:{"product":item,"radius":"0%","badge":true,"aspectRatio":1 / 1,"imageInInfo":true},on:{"goProduct":function($event){return _vm.goProductPage(item, 'hot', index)},"isLikeChange":(yn, productNo) => _vm.isLikeChange(yn, productNo, index, 'hot')}})],1)}),0)]),_c('v-tab-item',[_c('div',{staticClass:"d-flex align-content-start flex-wrap overflow-hidden"},_vm._l((_vm.getTrendHistory.trendThesedayRiseKeywordList),function(item,index){return _c('span',{key:item.id,class:`chip font-g13-13 rixgo-medium ${
              _vm.active.rise === item.id
                ? 'blue white--text box-shadow rixgo-bold'
                : 'light_blue gray600--text'
            } px-7 py-6 mr-4 mb-4`,on:{"click":function($event){return _vm.setActiveCate(item, index, 'rise')}}},[_vm._v(_vm._s(item.title))])}),0),_c('div',{staticClass:"d-flex pt-4 flex-wrap"},_vm._l((_vm.riseList.slice(0, 9)),function(item,index){return _c('div',{key:item.productNo,staticClass:"width-33",staticStyle:{"padding":"0.23%"}},[_c('Product',{attrs:{"product":item,"radius":"0%","badge":true,"aspectRatio":1 / 1,"imageInInfo":true},on:{"goProduct":function($event){return _vm.goProductPage(item, 'rise', index)},"isLikeChange":(yn, productNo) => _vm.isLikeChange(yn, productNo, index, 'hot')}})],1)}),0)])],1)],1),_c('div',{staticClass:"mt-15"},_vm._l((_vm.getPlanexHistory.planexItemList),function(item,index){return _c('div',{key:item.link,class:`${index === 0 && 'border-top-gray'} ${
        index === _vm.getPlanexHistory.planexItemList.length - 1
          ? `border-bottom-gray-2px`
          : `border-bottom-gray`
      } d-flex font-g14-15 py-6`,on:{"click":function($event){return _vm.goPlanPage(item, index)}}},[_c('div',{staticClass:"font-g14-16 rixgo-bold blue--text ml-5 mr-4 oneLine"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('span',{staticClass:"font-g14-16 rixgo-regular pl-3 oneLine black--text",domProps:{"innerHTML":_vm._s(_vm.$replaceHtml2(item.subTitle))}})])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }