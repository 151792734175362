
import Vue from 'vue'

import { mapActions, mapGetters, mapState } from 'vuex'
import Main from '@/components/views/main/Main.vue'
import AllItems from '@/components/views/main//AllItems.vue'
import MdPick from '@/components/views/main//MdPick.vue'
import Sale from '@/components/views/main//Sale.vue'
import Store from '@/components/views/main/Store.vue'

export default Vue.extend<Data, Methods, Computed>({
  name: 'MainTab',
  components: { Main, AllItems, MdPick, Sale, Store },
  data() {
    return {
      init: false,
      scroll: 0,
    }
  },
  created() {
    this.$getAppHtml.addEventListener('scroll', this.setScroll)
    window.addEventListener('beforeunload', this.setHistoryScroll)
  },
  computed: {
    ...mapState('BannerStore', ['onBanner']),
    ...mapState('HomeStore', ['redirect', 'homeSwipeBlock']),
    ...mapGetters('HomeStore', ['getMenuHistory']),
    tab: {
      get() {
        return this.$store.state.HomeStore.redirect.activeTab
      },
      set(value) {
        this.fetchMainTab({ tab: value })
      },
    },
    headerAnimClass() {
      if (this.onBanner) {
        return `common-header-visible`
      } else {
        return 'common-header-animate'
      }
    },
  },
  watch: {
    tab(now, pre) {
      // Tab 이동 시, scroll event 방지
      // IOS에서 Tab 이동 중 scroll event 시 화면 깨지는 issue 보완처리
      // 추후, 해당 내용 재검토 필요
      try {
        this.fetchCheckInNo()
      } catch (e) {
        console.log(e)
      }

      this.$getAppHtml.style.overflowY = 'hidden'
      setTimeout(() => {
        this.$getAppHtml.style.overflowY = 'scroll'
      }, 1000)
      sessionStorage.setItem('appInit', 'N')
      switch (now) {
        case 0:
          this.$tracking('home_main')
          break
        case 1:
          this.$tracking('home_store')
          break
        case 2:
          this.$tracking('home_Items')
          break
        case 3:
          this.$tracking('home_mdpick')
          break
        case 4:
          this.$tracking('home_sale')
          break
      }
      this.fetchRedirect({ now, pre, scroll: this.scroll })
    },
  },
  methods: {
    ...mapActions('HomeStore', [
      'fetchRedirect',
      'fetchScroll',
      'fetchMainTab',
    ]),
    ...mapActions('CheckInStore', ['fetchCheckInNo']),

    handleChange(key: number): void {
      this.tab = key
    },
    setScroll() {
      this.scroll = this.$getAppHtml.scrollTop
    },
    setHistoryScroll() {
      this.fetchScroll({ scroll: this.scroll })
    },
  },
  beforeDestroy() {
    this.setHistoryScroll()
    this.$getAppHtml.removeEventListener('scroll', this.setScroll)
    window.removeEventListener('beforeunload', this.setHistoryScroll)
  },
})

interface Data {
  // tab: number
  init: boolean
  scroll: number
}

interface Computed {
  onBanner: boolean
  redirect: { activeTab: number; scroll: number[] }
  tab: number
  homeSwipeBlock: boolean
  headerAnimClass: string
  getMenuHistory: TabMenuItemGroup
}

interface Methods {
  handleChange(key: number): void
  fetchRedirect(payload: { now: number; pre?: number; scroll: number }): void
  fetchScroll(payload: { scroll: number }): void
  setScroll(): void
  fetchMainTab: (payload: { tab: number }) => void
  setHistoryScroll(): void
  fetchCheckInNo: () => Promise<void>
}
