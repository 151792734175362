
import Vue from 'vue'
import { mapActions } from 'vuex'
import MdPickProduct from '@/components/common/cards/MdPickProduct.vue'
import { ProductItemData } from '@/util/default/common'
import { DeleteSubscribe, PostSubscribe } from '@/api/displayAPI/UserAPI'
import Alert from '@/components/common/dialogs/Alert.vue'
import Subscribe from '@/components/common/buttons/Subscribe.vue'

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { Subscribe, MdPickProduct, Alert },
  props: {
    mdPickItem: {
      type: Object,
      default: () => ({
        description: '',
        icon: '',
        isSubscribed: false,
        mdPickShopSubItemList: [],
        shopId: '',
        shopName: '',
        subscribeCount: 0,
        shopType: 0,
      }),
    },
    gtagName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      productItemInfo: ProductItemData,
      active: this.mdPickItem.isSubscribed,
      count: this.mdPickItem.subscribeCount,
      alert: {
        show: false,
        message: '',
      },
    }
  },
  watch: {
    mdPickItem(data) {
      this.active = data.isSubscribed
      this.count = data.subscribeCount
    },
  },
  methods: {
    ...mapActions('DialogStore', ['fetchLoginDialog']),
    goPage() {
      if (this.gtagName) this.$tracking(`${this.gtagName}_mallname`)
      this.$router.push({
        name: 'StoreHome',
        query: { shopId: this.mdPickItem.shopId },
      })
    },
    async handleActive(yn: boolean) {
      if (!this.isLogined) {
        await this.fetchLoginDialog({ yn: true })
        return
      }
      const { shopId } = this.mdPickItem
      yn ? await this.subscribe(shopId) : await this.unSubscribe(shopId)
    },
    async subscribe(subShopId) {
      if (!subShopId) return
      try {
        const { shopName, shopId, shopType } = this.mdPickItem
        await PostSubscribe(subShopId, shopName)
        this.$emit('isSubscribeChange', true, shopId, shopType)
        if (this.gtagName) this.$tracking(`${this.gtagName}_subscribe`)
        this.active = true
        this.count += 1
      } catch (e: any) {
        if (e && e.error_code === 400) {
          this.alert = { show: true, message: e.message }
        }
      }
    },
    async unSubscribe(subShopId) {
      if (!subShopId) return
      try {
        const { shopName, shopId, shopType } = this.mdPickItem
        await DeleteSubscribe(subShopId, shopName)
        this.$emit('isSubscribeChange', false, shopId, shopType)
        if (this.gtagName) this.$tracking(`${this.gtagName}_unsubscribe`)
        this.active = false
        this.count -= 1
      } catch (e) {
        console.log(e)
      }
    },
    isLikeChange(isLike: boolean, productNo: string) {
      this.$emit('isLikeChange', isLike, productNo)
    },
  },
})
interface Data {
  productItemInfo: any
  active: boolean
  count: number
  alert: {
    show: boolean
    message: string
  }
}
interface Methods {
  fetchLoginDialog({ yn }: { yn: boolean }): void
  goPage(): void
  handleActive(yn: boolean): void
  subscribe(data: string): void
  unSubscribe(data: string): void
  isLikeChange(isLike: boolean, productNo: string): void
}
interface Computed {}
interface Props {
  mdPickItem: MdPickShopList
  gtagName: string
}
