
import Vue from 'vue'
import { mapState, mapActions } from 'vuex'
import MainTab from '@/components/common/tabs/MainTab.vue'

export default Vue.extend({
  components: { MainTab },
  data: () => ({}),
  async created() {
    if (localStorage.getItem('preHomeHistory')) {
      await this.fetchHomeHistory().then(() => {
        const { scroll, activeTab } = this.redirect
        this.$getAppHtml.scrollTo({ top: scroll[activeTab] })
      })
    }
  },
  methods: {
    ...mapActions('HomeStore', ['fetchHomeHistory']),
    ...mapActions('DisplayStore', ['asyncGetDisplay']),
  },
  computed: {
    ...mapState('HomeStore', ['redirect', 'pageHistory']),
  },
})
