
import Vue from 'vue'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { mapActions, mapGetters, mapState } from 'vuex'
import { PostAdOnClick, PostAdOnView } from '@/api/displayAPI/DisplayAPI'
import { adOnTracking } from '@/plugins/util'
import MallBannerCard from '@/components/common/widgets/MallBannerCard.vue'
import VueSlickCarousel from 'vue-slick-carousel'

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'TopShop',
  components: {
    MallBannerCard,
    VueSlickCarousel,
  },
  data() {
    return {
      initCarousel: 0,
      carouselOption: {
        centerMode: true,
        centerPadding: '10px',
        infinite: true,
        slidesToShow: 1,
        speed: 500,
      },
      block: false,
      activeIndex: 0,
      isCompleteShowAdvertisingBannerParent: false,
    }
  },
  mounted() {
    this.initCarousel = this.getTopShopHistory.active
  },
  computed: {
    ...mapState('HomeStore', ['redirect', 'pageHistory']),
    ...mapGetters('HomeStore', ['getTopShopHistory']),
    ...mapGetters('DisplayStore', ['getTopShop']),
    ...mapGetters('BannerStore', ['getAdvertisingBanner']),
    adBanners() {
      const { bannerList } = this.getAdvertisingBanner
      if (bannerList.length < 2) {
        return bannerList
      }

      return [...bannerList, ...bannerList]
    },
  },
  watch: {
    activeIndex() {
      if (this.redirect.activeTab !== 0) return
      if (!this.isCompleteShowAdvertisingBannerParent) return

      const { bannerViewEventUrl, isLoaded } = this.adBanners[this.activeIndex]
      if (!bannerViewEventUrl || !isLoaded) return

      adOnTracking(bannerViewEventUrl, (key) => PostAdOnView({ key: key }))
    },
  },
  methods: {
    ...mapActions('HomeStore', ['fetchMainHistory']),
    ...mapActions('DisplayStore', ['fetchTopShopItemGroup']),
    handleActive(active: number) {
      this.fetchMainHistory({
        id: 'topShop',
        topShop: { ...this.getTopShopHistory, active },
      })
    },
    goPage(title: string) {
      this.$tracking('home_topshop_more')
      this.$router.push({
        name: 'CategoryTopShopList',
        query: { categoryName: title },
      })
    },
    goBannerPage(link: string, index: number) {
      adOnTracking(this.adBanners[this.activeIndex].bannerClickUrl, (key) =>
        PostAdOnClick({ key: key })
      )

      this.$tracking(`banner_main_long_02_${this.$numbering(index + 1)}`)
      const payload = {
        url: link,
        title: 'CELLOOK',
        isGoneBottomView: false,
      }
      this.$rendingOutsidePageOrInternal(this.$router, this.$device, payload)
    },
    onIntersect(entries: IntersectionObserverEntry[]) {
      this.isCompleteShowAdvertisingBannerParent =
        entries[0].intersectionRatio > 0.99
    },
    bannerImgLoad(loadParam: object | string) {
      this.adBanners.find((value) => {
        return value.icon === loadParam
      }).isLoaded = true
    },
    isLikeChange(isLike, productNo) {
      const { topShopItemList, active } = this.getTopShopHistory
      const replaceList = topShopItemList
      const idx = replaceList[active].searchProductList.findIndex(
        (item) => item.productNo === productNo
      )
      replaceList[active].searchProductList[idx].isLike = isLike

      this.fetchMainHistory({
        id: 'topShop',
        topShop: { ...this.getTopShopHistory, topShopItemList: replaceList },
      })
    },
  },
})

interface Data {
  initCarousel: number
  carouselOption: SlickOption
  activeIndex: number
  isCompleteShowAdvertisingBannerParent: boolean
}
interface Methods {
  handleActive(active: number): void
  fetchMainHistory(payload: MainHistoryPayload): void
  fetchTopShopItemGroup(): void
  goPage(title: string): void
  goBannerPage(link: string, index: number): void
  onIntersect(entries: IntersectionObserverEntry[]): void
  bannerImgLoad(loadParam: object | string): void
  isLikeChange(isLike: boolean, productNo: string): void
}
interface Computed {
  getTopShop: TopShopItemGroup
  getAdvertisingBanner: AdonBannerList
  getTopShopHistory: TopShopInfo
  pageHistory: HomeHistory
  adBanners: any[]
  redirect: any
}

interface Props {}
