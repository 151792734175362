var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PullToRefresh',{on:{"handleRefresh":_vm.fetch}},[_c('div',{staticClass:"bottom-margin"},[_c('div',{staticClass:"pt-10 px-10"},[_c('div',{staticClass:"font-g20-23 gray900--text campton-bold"},[_vm._v(" "+_vm._s(_vm.getMdPickHistory.title)+" ")]),_c('div',{staticClass:"font-g12-13 gray700--text pt-2"},[_vm._v(" "+_vm._s(_vm.getMdPickHistory.subTitle)+" ")])]),_c('div',[_vm._l((_vm.getMdPickHistory.mdPickShopItemList),function(item,index){return _c(_vm._f("displayComponent")(item.shopType,index),{key:index,tag:"component",class:index === 0 ? 'pt-10' : 'pt-30',attrs:{"mdPickItem":item,"gtagName":`mdpick_${_vm.$numbering(index + 1)}`},on:{"isLikeChange":_vm.isLikeChange,"isSubscribeChange":_vm.isSubscribeChange}})}),(_vm.getMdpickAdBottomBanner.bannerList.length)?_c('v-carousel',{directives:[{name:"intersect",rawName:"v-intersect",value:({
          handler: _vm.onIntersect,
          options: {
            rootMargin: '0px 0px -75px 0px',
            threshold: [0, 0.99, 1.0],
          },
        }),expression:"{\n          handler: onIntersect,\n          options: {\n            rootMargin: '0px 0px -75px 0px',\n            threshold: [0, 0.99, 1.0],\n          },\n        }"}],staticClass:"mt-15 px-10",attrs:{"hide-delimiters":"","height":"auto","cycle":"","show-arrows":false,"interval":_vm.getMdpickAdBottomBanner.rollingSecTime * 1000}},_vm._l((_vm.getMdpickAdBottomBanner.bannerList),function(item,index){return _c('v-carousel-item',{key:index},[_c('v-img',{attrs:{"src":item.icon,"aspect-ratio":335 / 60},on:{"~load":function($event){return _vm.bannerLoadCheck.apply(null, arguments)},"click":function($event){return _vm.goBannerPage(item.link)}}})],1)}),1):_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }