
import Vue from 'vue'

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'Subscribe',
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
  },
  methods: {
    handleActive() {
      this.$emit('handleActive')
    },
  },
})
interface Data {}
interface Methods {
  handleActive(): void
}
interface Computed {}
interface Props {}
