
import Vue from 'vue'

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    buttonTitle: {
      type: String,
      required: true,
    },
    borderColor: {
      type: String,
      default: '#003399',
    },
    textColor: {
      type: String,
      default: 'blue',
    },
    fillColor: {
      type: String,
      default: '#00000000',
    },
    isStore: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  methods: {
    more() {
      this.$emit('more')
    },
  },
})

interface Data {
  name: string
}

interface Methods {
  more(): void
}
interface Computed {}

interface Props {
  buttonTitle: string
  borderColor: string
  textColor: string
  fillColor: string
  isStore: boolean
}
